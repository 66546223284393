import React from "react";
//components
import LandingPageHeader from "../includes/LandingPageHeader";
import Spotlight from "./landingpages/Spotlight";
import Amenities from "./landingpages/Amenities";
import Events from "./landingpages/Events";
import Models from "./landingpages/Models";
import Footer from "../includes/Footer";
import styled from "styled-components";

function TechiesParkLandingPage() {
    return (
        <Container>
            <LandingPageHeader />
            <Spotlight />
            <Amenities />
            <Events />
            <Models />
            <Footer />
        </Container>
    );
}
const Container = styled.div``;

export default TechiesParkLandingPage;
