import React, { useEffect, useState } from "react";
import styled from "styled-components";
import $ from "jquery";
import { Link, NavLink, useParams } from "react-router-dom";
import { Link as scrolLink } from "react-scroll";

const HeaderHamburgerModal = ({
    isMenu,
    setMenu,
    active,
    setActive,
    eventsList,
}) => {
    useEffect(() => {
        if (isMenu) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isMenu]);
    const { techiespark_id } = useParams();
    return (
        <BackContainer style={{ top: isMenu && 0 }}>
            <Overlay onClick={() => setMenu(false)}></Overlay>

            {eventsList === "eventsList" ? (
                <Modal>
                    <Cover>
                        <MenuContainer>
                            <LeftSection>
                                <HamburgerMenu
                                    to={`/techies-park/${techiespark_id}/`}
                                    onClick={() => {
                                        setMenu(false);
                                    }}
                                >
                                    Home
                                </HamburgerMenu>
                                <HamburgerMenu
                                    to={`/techies-park/${techiespark_id}/events/`}
                                    onClick={() => {
                                        setMenu(false);
                                    }}
                                >
                                    Events
                                </HamburgerMenu>
                            </LeftSection>
                        </MenuContainer>
                    </Cover>
                </Modal>
            ) : (
                <Modal>
                    <Cover>
                        <MenuContainer>
                            <LeftSection>
                                <Para
                                    activeClass="active"
                                    to="home"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={300}
                                    onClick={() => {
                                        setMenu(false);
                                    }}
                                >
                                    Home
                                </Para>
                                <Para
                                    activeClass="active"
                                    to="amenities"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={300}
                                    onClick={() => {
                                        setMenu(false);
                                    }}
                                >
                                    Amenities
                                </Para>

                                <Para
                                    activeClass="active"
                                    to="events"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={300}
                                    onClick={() => {
                                        setMenu(false);
                                    }}
                                >
                                    Events
                                </Para>
                                <Para
                                    activeClass="active"
                                    to="revenue"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={300}
                                    onClick={() => {
                                        setMenu(false);
                                    }}
                                >
                                    Revenue Models
                                </Para>
                                <Button
                                    className="dashboard"
                                    activeClass="active"
                                    to="/"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={300}
                                    onClick={() => {
                                        setMenu(false);
                                    }}
                                >
                                    Go to Dashboard
                                </Button>
                            </LeftSection>
                        </MenuContainer>
                    </Cover>
                </Modal>
            )}
        </BackContainer>
    );
};

export default HeaderHamburgerModal;

const BackContainer = styled.div`
    overflow: hidden;
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: -100vh;
    backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
`;
const Modal = styled.div`
    background: #f0f8ee;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    transition: 0.5s;
    z-index: 101;
    // min-height: 40vh;
    padding: 50px 0;
    overflow-y: scroll;
    max-height: 100vh;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    @media all and (max-width: 360px) {
        padding: 50px 0 20px 0;
        min-height: unset;
    }
`;

const MenuContainer = styled.div`
    margin-top: 50px;
    display: grid;
    grid-template-columns: 3fr 2fr;
    @media all and (max-width: 980px) {
        grid-template-columns: 1fr;
    }
`;
const Cover = styled.div`
    position: relative;
    height: 100%;
    width: 85%;
    margin: 0 auto;
`;
const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    @media all and (max-width: 980px) {
        // padding-bottom: 50px;
    }
    @media all and (max-width: 768px) {
        // padding-bottom: 30px;
        /* justify-content: space-between; */
    }
    @media all and (max-width: 360px) {
        // padding-bottom: 20px;
    }
`;
const Para = styled(scrolLink)`
    font-size: 20px;
    margin: 20px 0;
    font-family: gordita_medium;
    cursor: pointer;
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
    &.active {
        color: #0fa76f;
    }
`;
const Button = styled(Link)`
    font-size: 20px;
    margin: 20px 0;
    font-family: gordita_medium;
    color: #fff;
    background: #0fa76f;
    border-radius: 7px;
    padding: 12px 20px;
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
const HamburgerMenu = styled(NavLink)`
    font-size: 20px;
    margin: 20px 0;
    font-family: gordita_medium;
    cursor: pointer;
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
    &.active {
        color: #0fa76f;
    }
`;
const RightSection = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    // border-left: 1px solid #d2f7ea;
    grid-gap: 30px;
    @media all and (max-width: 980px) {
        border-left: none;
        // border-top: 1px solid #8282821a;
        padding-top: 50px;
        flex-direction: row;
        justify-content: space-around;
    }
    @media all and (max-width: 768px) {
        padding-top: 30px;
    }
    @media all and (max-width: 480px) {
        flex-direction: column;
        justify-content: center;
        grid-gap: 20px;
    }
    @media all and (max-width: 360px) {
        padding-top: 20px;
    }
`;
