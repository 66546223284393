import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components";
import { Link } from "react-scroll";
//components
import HeaderHamburgerModal from "./HeaderHamburgerModal";

export default function LandingPageHeader({ eventsList }) {
    const [active, setActive] = useState("");
    const [isModal, setModal] = useState(false);
    const { techiespark_id } = useParams();

    return (
        <>
            <HeaderHamburgerModal
                isMenu={isModal}
                setMenu={setModal}
                active={active}
                setActive={setActive}
                eventsList={eventsList}
            />
            <Container>
                <div className="wrapper">
                    <Logo>
                        <LinkTag to="/techies-parks/">
                            <img
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/Techiespark_Logo.svg"
                                }
                                alt="Logo"
                            />
                        </LinkTag>
                    </Logo>
                    {eventsList === "eventsList" ? (
                        <Nav>
                            <EventLinkHome
                                activeClass="active"
                                href={`/techies-park/${techiespark_id}/`}
                            >
                                Home
                            </EventLinkHome>
                            <EventLink
                                activeClass="active"
                                to="events"
                                spy={true}
                            >
                                Events
                            </EventLink>
                        </Nav>
                    ) : (
                        <Nav>
                            <NavItem activeClass="active" to="home" spy={true}>
                                Home
                            </NavItem>
                            <NavItem
                                onClick={() => setActive(true)}
                                activeClass="active"
                                to="amenities"
                                spy={true}
                            >
                                Amenities
                            </NavItem>
                            <NavItem
                                activeClass="active"
                                to="events"
                                spy={true}
                            >
                                Events
                            </NavItem>
                            <NavItem
                                activeClass="active"
                                to="revenue"
                                spy={true}
                            >
                                Revenue Models
                            </NavItem>
                        </Nav>
                    )}
                    <HumbergContainer onClick={() => setModal(!isModal)}>
                        <Hamberg
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/humbergimage.svg"
                            }
                            alt="image"
                        />
                    </HumbergContainer>
                </div>
            </Container>
        </>
    );
}

const Container = styled.div`
    min-height: 100px;
    width: 100%;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 1000;

    & .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;
const Logo = styled.h1`
    width: 16%;

    @media all and (max-width: 980px) {
        width: 20%;
    }
    @media all and (max-width: 768px) {
        width: 28%;
    }
    @media all and (max-width: 640px) {
        width: 35%;
    }
    @media all and (max-width: 480px) {
        width: 44%;
    }
`;
const LinkTag = styled(NavLink)`
    font-size: 28px;
    font-family: "gordita_bold";
    color: #247d3d;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;
const Nav = styled.nav`
    display: flex;

    @media all and (max-width: 768px) {
        display: none;
    }
`;
const NavItem = styled(Link)`
    font-size: 16px;
    /* color: #666666; */
    color: ${({ active }) => (active ? "#4eaf7c" : "#666666")};
    font-family: "gordita_medium";
    margin-right: 50px;
    cursor: pointer;
    &:last-child {
        margin-right: 0;
    }
    &.active {
        color: #4eaf7c !important;
    }
    @media all and (max-width: 1080px) {
        margin-right: 30px;
    }
    @media all and (max-width: 900px) {
        margin-right: 20px;
    }
`;
const EventLink = styled(Link)`
    font-size: 18px;
    color: #666666;
    font-family: "gordita_medium";
    margin-right: 50px;
    cursor: pointer;
    &:last-child {
        margin-right: 0;
    }
    &.active {
        color: #4eaf7c;
    }
    &:hover {
        color: #4eaf7c;
    }
    @media all and (max-width: 1080px) {
        margin-right: 30px;
    }
    @media all and (max-width: 900px) {
        margin-right: 20px;
    }
`;
const EventLinkHome = styled.a`
    font-size: 18px;
    color: #666666;
    font-family: "gordita_medium";
    margin-right: 50px;
    cursor: pointer;
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        color: #4eaf7c;
    }
    @media all and (max-width: 1080px) {
        margin-right: 30px;
    }
    @media all and (max-width: 900px) {
        margin-right: 20px;
    }
`;
const Hamburger = styled.div`
    width: 50px;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    cursor: pointer;
    display: none;
    @media all and (max-width: 768px) {
        display: flex;
    }
`;
const LineSpan = styled.span`
    cursor: pointer;
    width: ${({ type, isModal }) =>
        !isModal ? (type === "middle" ? "35px" : "45px") : "40px"};
    height: 5px;
    background: ${({ type }) => (type === "last" ? "#96CA4C" : "#18484C")};
    margin-bottom: 8px;
    border-radius: 30px;
    &:first-child {
        transform: ${({ isModal }) =>
            isModal ? "translateY(13px) rotate(45deg)" : "rotate(0deg)"};
        transition: all 0.5s ease;
    }
    &:nth-child(2) {
        transform: ${({ isModal }) =>
            isModal ? "translateX(-12px)" : "translateX(0px)"};
        opacity: ${({ isModal }) => (isModal ? "0" : "1")};
        transition: all 0.5s ease;
    }
    &:nth-child(3) {
        transform: ${({ isModal }) =>
            isModal ? "translateY(-13px) rotate(-45deg)" : "rotate(0deg)"};
        transition: all 0.5s ease;
    }
    transition: all 0.5s ease;
    @media all and (max-width: 960px) {
        width: ${({ type, isModal }) =>
            !isModal ? (type === "middle" ? "28px" : "38px") : "33px"};
        margin-bottom: 5px;
        &:first-child {
            transform: ${({ isModal }) =>
                isModal ? "translateY(10px) rotate(45deg)" : "rotate(0deg)"};
            transition: all 0.5s ease;
        }
        &:nth-child(2) {
            transition: all 0.5s ease;
        }
        &:nth-child(3) {
            transform: ${({ isModal }) =>
                isModal ? "translateY(-10px) rotate(-45deg)" : "rotate(0deg)"};
            transition: all 0.5s ease;
        }
    }
    @media all and (max-width: 540px) {
        width: ${({ type, isModal }) =>
            !isModal ? (type === "middle" ? "22px" : "30px") : "28px"};
        margin-bottom: 5px;
        &:first-child {
            transform: ${({ isModal }) =>
                isModal ? "translateY(10px) rotate(45deg)" : "rotate(0deg)"};
            transition: all 0.5s ease;
        }
        &:nth-child(2) {
            transition: all 0.5s ease;
        }
        &:nth-child(3) {
            transform: ${({ isModal }) =>
                isModal ? "translateY(-10px) rotate(-45deg)" : "rotate(0deg)"};
            transition: all 0.5s ease;
        }
    }
`;
const HumbergContainer = styled.div`
    width: 75px;
    display: none;
    @media all and (max-width: 768px) {
        display: block;
    }
`;
const Hamberg = styled.img`
    width: 100%;
    display: block;
`;
