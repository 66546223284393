import React, { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
//components
import { Context } from "../../contexts/Store";
import PlaceHolder from "../../general/PlaceHolder";
import { getMonthDateYearStr } from "../../helpers/functions";

export default function EventCard({ item, index }) {
    const { techiespark_id } = useParams();
    const { dispatch } = useContext(Context);
    //image loading
    const [imageLoading, setImageLoading] = useState(true);
    function onLoad() {
        setTimeout(() => {
            setImageLoading(false);
        }, 1000);
    }
    //remove tags from the description
    let description = item?.description?.replace(/\n/g, "<br />");

    const markupDescription = () => {
        return {
            __html: description,
        };
    };

    return (
        <SubList
            to={`/techies-park/${techiespark_id}/events/view/${item.id}/`}
            key={item.id}
            style={{
                backgroundColor: `${item.background}`,
                borderColor: `${item.borderColor}`,
            }}
            onClick={(e) => {
                dispatch({
                    type: "UPDATE_EVENTS_DATA",
                    events_data: {
                        event_no: index + 1,
                    },
                });
            }}
        >
            <TopContainer>
                <MainImgContainer>
                    <PlaceHolder isLoading={imageLoading} paddingTop="61.5%" />
                    <MainImg
                        style={{
                            display: imageLoading ? "none" : "block",
                        }}
                        src={item.thumbnail_image}
                        onLoad={onLoad}
                        alt="Image"
                    />
                    <MeetingButton>{item.program}</MeetingButton>
                </MainImgContainer>
                <DateHeading>
                    {getMonthDateYearStr(item.event_date)}
                </DateHeading>
                <MainDetailHeading>{item.title}</MainDetailHeading>
                <SubDetailHeading
                    style={{
                        fontSize: "16px",
                    }}
                    dangerouslySetInnerHTML={markupDescription()}
                />
            </TopContainer>
            <BottomContainer>
                <Button>
                    View More
                    <ButtonImgContainer>
                        <ButtonImg
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/Arrow-icon.svg"
                            }
                            alt="ButtonImage"
                        />
                    </ButtonImgContainer>
                </Button>
            </BottomContainer>
        </SubList>
    );
}

const SubList = styled(Link)`
    padding: 25px;
    border: 1px solid #b8f2ca;
    border-radius: 2%;
    background-color: #f7fff9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: 300ms ease-in-out 0ms 1 normal none running fade;
    @keyframes fade {
        0% {
            opacity: 0;
            transform: scale(0.9);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    @media all and (max-width: 1200px) {
        padding: 15px;
    }
    @media all and (max-width: 1080px) {
        padding: 25px;
    }
    @media all and (max-width: 480px) {
        padding: 15px;
    }
`;
const TopContainer = styled.div``;
const MainImgContainer = styled.div`
    width: 100%;
    position: relative;
    margin-bottom: 25px;
`;
const MeetingButton = styled.div`
    position: absolute;
    font-size: 12px;
    padding: 8px 20px;
    color: #fff;
    background: rgba(0, 0, 0, 0.56);
    border-radius: 60px;
    bottom: 10px;
    left: 10px;
    @media all and (max-width: 980px) {
        font-size: 11px;
    }
    @media all and (max-width: 360px) {
        font-size: 10px;
    }
`;
const MainImg = styled.img`
    width: 100%;
    display: block;
    border-radius: 7px;
`;
const DateHeading = styled.div`
    border: 1px solid #0fa76f;
    font-size: 13px;
    color: #0fa76f;
    padding: 10px 14px;
    text-align: center;
    border-radius: 4px;
    width: max-content;
    font-family: "gordita_medium";
    margin-bottom: 15px;
`;
const MainDetailHeading = styled.h4`
    margin-top: 10px;
    font-family: "gordita_medium";
    color: #0a0a0a;
    font-size: 18px;
    margin-bottom: 15px;
    @media all and (max-width: 1280px) {
        font-size: 16px;
    }
    @media all and (max-width: 360px) {
        font-size: 15px;
    }
`;
const SubDetailHeading = styled.p`
    font-size: 16px !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
    overflow: hidden;
    margin-bottom: 50px;
    line-height: 1.6em;
    color: #868686;
    font-family: gordita_regular;
    @media all and (max-width:1400px){
        p {
            font-size: 14px !important;
        }
    }
    
    @media all and (max-width: 768px) {
        margin-bottom: 50px;
    }
    @media all and (max-width: 640px) {
        margin-bottom: 30px;
    }
    @media all and (max-width: 480px) {
        -webkit-line-clamp: 6;
        font-size: 14px !important;
    }
    @media all and (max-width: 360px) {
        font-size: 13px !important;
    }
`;
const ButtonImgContainer = styled.div`
    width: 7px;
    margin-left: 15px;
    @media all and (max-width: 768px) {
        width: 6px;
    }
`;
const ButtonImg = styled.img`
    width: 100%;
    display: block;
`;

const BottomContainer = styled.div``;
const Button = styled.div`
    cursor: pointer;
    padding: 13px 20px;
    color: #fff;
    border-radius: 4px;
    justify-content: space-around;
    align-items: center;
    display: flex;
    width: max-content;
    font-size: 14px;
    transition: all 0.4s ease-in;
    background: #0fa76f;
    opacity: 0.9;
    &:hover {
        background-color: #0c7d54;
        transition: all 0.4s ease-in;
        opacity: 1;
    }
    @media all and (max-width: 640px) {
        padding: 12px 20px;
    }
    @media all and (max-width: 480px) {
        padding: 10px 15px;
        font-size: 13px;
    }
`;
