import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Context } from "../../contexts/Store";
import { useParams } from "react-router-dom";
//axios
import { techiesparkConfig } from "../../../axiosConfig";
//modal
import SingleEventModal from "../../includes/events/modal/SingleEventModal";
//components
import SectionLoader from "../../includes/loaders/SectionLoader";
import PlaceHolder from "../../general/PlaceHolder";

function Amenities() {
    const { techiespark_id } = useParams();
    const [isModal, setModal] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [data, setData] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [selectedId, setSelectedId] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState({});
    //image loading
    const [imageLoading, setImageLoading] = useState(true);
    function onLoad() {
        setTimeout(() => {
            setImageLoading(false);
        }, 1500);
    }
    const {
        state: {
            user_data: { access_token },
        },
        dispatch,
    } = useContext(Context);

    useEffect(() => {
        techiesparkConfig
            .get(`techies-parks/amenities/${techiespark_id}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then(function (response) {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setData(data);
                    setLoading(false);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: "UPDATE_ERROR",
                    error: err,
                    errorMessage: "Server error please try again",
                });
                setLoading(false);
            });
    }, []);

    return (
        <>
            {data.length > 0 && (
                <div>
                    <SingleEventModal
                        selectedId={selectedId}
                        setSelectedId={setSelectedId}
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        gallery={gallery}
                        isModal={isModal}
                        setModal={setModal}
                    />
                    <MainContainer id="amenities">
                        <div className="inner-wrapper">
                            <Heading>
                                <Span>
                                    Amenities
                                    <img
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/under-line.svg"
                                        }
                                        alt="image"
                                    />
                                </Span>
                            </Heading>
                            <Sentence>
                                Techies Park is a world-class infrastructure
                                with premium amenities to promote education and{" "}
                                <br /> entrepreneurship and thereby aid overall
                                development of the constituency.
                            </Sentence>
                            {isLoading ? (
                                <Cover>
                                    <SectionLoader />
                                </Cover>
                            ) : (
                                <DivContainer>
                                    {data.map((item, index) => {
                                        return (
                                            <Container
                                                key={item.id}
                                                onClick={() => {
                                                    setGallery(item.gallery);
                                                    setCurrentIndex(0);
                                                    setSelectedImage(
                                                        item.gallery[0].image
                                                    );
                                                    setSelectedId(
                                                        item.gallery[0].id
                                                    );
                                                }}
                                            >
                                                <PlaceHolder
                                                    isLoading={imageLoading}
                                                    paddingTop="75%"
                                                />
                                                <Image
                                                    style={{
                                                        display: imageLoading
                                                            ? "none"
                                                            : "block",
                                                    }}
                                                    onClick={() =>
                                                        setModal(true)
                                                    }
                                                    onLoad={onLoad}
                                                    src={item.gallery[0].image}
                                                    alt="image"
                                                />

                                                <Text>
                                                    <TitleText className="textone">
                                                        {item.amenity_name}
                                                    </TitleText>
                                                    <HoverTextBox className="texttwo">
                                                        {item.gallery.length >
                                                            1 && (
                                                            <HoverText
                                                                onClick={() =>
                                                                    setModal(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                +
                                                                {item.gallery
                                                                    .length -
                                                                    1}{" "}
                                                                More Images
                                                            </HoverText>
                                                        )}
                                                    </HoverTextBox>
                                                </Text>
                                                {item.gallery.length > 1 && (
                                                    <Div>
                                                        <Small>
                                                            {"1"} /{" "}
                                                            {
                                                                item.gallery
                                                                    .length
                                                            }
                                                        </Small>
                                                    </Div>
                                                )}
                                            </Container>
                                        );
                                    })}
                                </DivContainer>
                            )}
                        </div>
                    </MainContainer>
                </div>
            )}
        </>
    );
}

const MainContainer = styled.div`
    padding: 100px 0;
    animation: 300ms ease-in-out 0ms 1 normal none running fade;
    @keyframes fade {
        0% {
            opacity: 0;
            transform: scale(0.9);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    @media all and (max-width: 980px) {
        padding: 80px 0;
    }
    @media all and (max-width: 640px) {
        padding: 70px 0;
    }
    @media all and (max-width: 480px) {
        padding: 60px 0;
    }
    @media all and (max-width: 360px) {
        padding: 50px 0;
    }
`;
const Heading = styled.h1`
    display: inline-block;
    font-size: 30px;
    font-family: "gordita_medium";
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    @media all and (max-width: 640px) {
        font-size: 25px;
    }
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
    @media all and (max-width: 360px) {
        font-size: 20px;
    }
`;
const Span = styled.span`
    color: #0fa76f;
    display: inline-block;
    font-size: 30px;
    font-family: "gordita_medium";
    position: relative;
    img {
        width: 50%;
        position: absolute;
        left: 52%;
        bottom: 0;
    }
    @media all and (max-width: 640px) {
        font-size: 25px;
    }
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
    @media all and (max-width: 360px) {
        font-size: 20px;
    }
`;
const Sentence = styled.p`
    color: #747474;
    font-size: 16px;
    text-align: center;
    margin-bottom: 50px;
    text-align: center;
    font-family: "gordita_regular";
    display: flex;
    align-items: center;
    justify-content: center;
    @media all and (max-width: 980px) {
        width: 100%;
        br {
            display: none;
        }
    }
    @media all and (max-width: 640px) {
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 13px;
    }
`;

const DivContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    @media all and (max-width: 980px) {
        grid-gap: 10px;
    }
    @media all and (max-width: 900px) {
        grid-template-columns: 1fr 1fr;
    }
    @media all and (max-width: 640px) {
        grid-gap: 5px;
    }
    @media all and (max-width: 540px) {
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }
`;
const Container = styled.div`
    padding: 8px;
    background-color: #f9f9f9;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        & .texttwo {
            opacity: 1;
        }
        & .textone {
            opacity: 0;
        }
    }
    animation: 300ms ease-in-out 0ms 1 normal none running fade;
    @keyframes fade {
        0% {
            opacity: 0;
            transform: scale(0.9);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
`;

const Image = styled.img`
    width: 100%;
    display: block;
    border-radius: 4px;
`;
const Text = styled.span`
    display: inline-block;
    color: #fff;
    font-size: 14px;
    font-family: "gordita_regular";
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    transform: translateX(-50%);

    @media all and (max-width: 540px) {
        bottom: 50px;
    }
`;
const TitleText = styled.div`
    text-align: center;
    transition: all 0.5s ease-in-out;
    font-family: "gordita_medium";
    @media all and (max-width: 640px) {
        font-size: 13px;
    }
    @media all and (max-width: 540px) {
        font-size: 14px;
    }
`;
const HoverTextBox = styled.div`
    transition: all 0.5s ease-in-out;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    font-family: "gordita_medium";
    transform: translate(-50%, -50%);
    opacity: 0;
    display: flex;
    cursor: pointer;
`;
const PhotoBox = styled.div`
    width: 20px;
    margin-right: 5px;
    @media all and (max-width: 640px) {
        width: 18px;
    }
`;
const HoverText = styled.div`
    border-bottom: 1px solid #fff;
    text-align: center;
    width: max-content;
    font-family: "gordita_medium";
    @media all and (max-width: 640px) {
        font-size: 13px;
    }
    @media all and (max-width: 540px) {
        font-size: 14px;
    }
`;
const Div = styled.div``;
const Small = styled.span`
    display: inline-block;
    color: #fff;
    font-size: 14px;
    font-family: "gordita_regular";
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 13px;
    padding: 6px 16px;
    top: 29px;
    right: 36px;
`;
const Cover = styled.div`
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default Amenities;
