import React, { useContext, useState } from "react";
import "../../../assets/css/style.css";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Context } from "../../contexts/Store";
//axios
import { techiesparkConfig } from "../../../axiosConfig";
//components
import ButtonLoader from "../../includes/loaders/ButtonLoader";

export default function Login() {
    const [hide, setHide] = useState(true);
    const [label, setLabel] = useState(false);
    const [labeltwo, setLabelTwo] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isError, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const { dispatch } = useContext(Context);
    const navigate = useNavigate();

    function myFunction() {
        setHide(!hide);
        var x = document.getElementById("myInput");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }
    //checkbox
    const [check, setCheck] = useState(true);

    //login api
    const handleLogin = (e) => {
        if (username && password) {
            e.preventDefault();
            setLoading(true);
            techiesparkConfig
                .post("users/login/", {
                    username: username,
                    password: password,
                })
                .then(function (res) {
                    const { status_code } = res.data;
                    if (status_code == 6000) {
                        setLoading(false);
                        dispatch({
                            type: "UPDATE_USER_DATA",
                            user_data: {
                                is_verified: true,
                                access_token: res.data.access_token,
                                name: username,
                            },
                        });
                        // setTimeout(() => {
                        //     navigate("nextPath");
                        // }, 1200);
                    } else {
                        setLoading(false);
                        setError(true);
                        setErrorMsg(res.data.message);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setError(true);
                    setErrorMsg("Something went wrong! try again");
                    dispatch({
                        type: "UPDATE_ERROR",
                        error: err,
                        errorMessage: "Server error please try again",
                    });
                });
        } else if (!username && password) {
            setError(true);
            setErrorMsg("Username Required");
        } else if (!password && username) {
            setError(true);
            setErrorMsg("Password Required");
        } else {
            setError(true);
            setErrorMsg("All Fields are Required");
        }
    };

    return (
        <div>
            <MainContainer>
                <Content>
                    <LeftContainer>
                        <Wrapper>
                            <Div>
                                <Heading>techies park</Heading>
                                <Sentence>
                                    Techies park is a technology and
                                    entrepreneurship hub, a project from Talrop.
                                    To build a strong startup ecosystem and
                                    accelerate startups, Talrop is establishing
                                    Techies Parks in all 140 Assembly
                                    Constituencies of Kerala.
                                </Sentence>
                            </Div>
                        </Wrapper>
                    </LeftContainer>
                    <RightContainer>
                        <WrapperDiv>
                            <RightContain>
                                <LogoDiv>
                                    <LogoContainer>
                                        <LogoIcon
                                            src={
                                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/logo.svg"
                                            }
                                            alt="logo"
                                        />
                                    </LogoContainer>
                                </LogoDiv>
                                <Small>Techies Park</Small>
                                <Text>
                                    A Project from <Color href="https://talrop.com/">Talrop</Color>
                                </Text>
                                <Paragraph>
                                    To aid the development of a strong startup
                                    ecosystem in our state, Talrop has designed
                                    Techies park
                                </Paragraph>

                                <DivInput>
                                    <InputContainer
                                        onClick={() => setLabel(!label)}
                                        className={label ? "active" : ""}
                                    >
                                        <LabelOne
                                            className={label ? "active" : ""}
                                            for="fname"
                                        >
                                            Username                                                                                                                                                                                                                                                                                                                                                
                                        </LabelOne>
                                        <InputField
                                            className="username"
                                            type="text"
                                            placeholder="Username"
                                            onChange={(e) => {
                                                setUsername(e.target.value);
                                            }}
                                            value={username}
                                        />
                                    </InputContainer>
                                    <InputOne
                                        className={labeltwo ? "active" : ""}
                                    >
                                        <LabelTwo
                                            className={labeltwo ? "active" : ""}
                                            for="fname"
                                        >
                                            Password
                                        </LabelTwo>
                                        <InputField
                                            onClick={() =>
                                                setLabelTwo(!labeltwo)
                                            }
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                            className="password"
                                            type="password"
                                            placeholder="Password"
                                            id="myInput"
                                            value={password}
                                        />
                                        <Eyespan onClick={() => myFunction()}>
                                            <Eye
                                                className={hide ? "show" : ""}
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/Eye.svg"
                                                }
                                                alt="image"
                                            />
                                            <EyeHidden
                                                className={
                                                    hide ? "deactive" : ""
                                                }
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/Hide.svg"
                                                }
                                                alt="image"
                                            />
                                        </Eyespan>
                                    </InputOne>
                                    <DivSign>
                                        <CheckBox
                                            onClick={() => setCheck(!check)}
                                            className={check ? "active" : ""}
                                        >
                                            <Tick
                                                className={
                                                    check ? "active" : ""
                                                }
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/greentick.svg"
                                                }
                                                alt="Tick"
                                            />
                                        </CheckBox>
                                        <Span>Keep me signed in</Span>
                                    </DivSign>
                                    {isError && <p>{errorMsg}</p>}
                                </DivInput>

                                <Button to="/" onClick={(e) => handleLogin(e)}>
                                    {isLoading ? <ButtonLoader /> : "Log In"}
                                </Button>
                            </RightContain>
                        </WrapperDiv>
                    </RightContainer>
                </Content>
            </MainContainer>
        </div>
    );
}

const MainContainer = styled.div`
    height: 100vh;
    overflow-y: scroll;
`;
const Content = styled.div`
    display: flex;
    justify-content: space-between;

    @media all and (max-width: 768px) {
        flex-wrap: wrap-reverse;
    }
`;
const Wrapper = styled.div`
    width: 90%;
    margin: 0 auto;
    max-width: 1325px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-items: flex-end;
`;
const WrapperDiv = styled.div`
    width: 65%;
    margin: 0 auto;
    @media all and (max-width: 480px) {
        width: 81%;
    }
    @media all and (max-width: 360px) {
        width: 91%;
    }

    width: 65%;
    margin: 0 auto;
    @media all and (max-width: 480px) {
        width: 81%;
    }
    @media all and (max-width: 360px) {
        width: 91%;
    }
`;
const RightContain = styled.div`
    padding: 60px 0;
`;
const Div = styled.div`
    padding: 0 0 60px 40px;
`;
const LeftContainer = styled.div`
    width: 50%;
    background-image: url(${"https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/backgroung-image.png"});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media all and (max-width: 768px) {
        width: 100%;
    }
`;
const Heading = styled.h5`
    color: #ffffff;
    text-transform: capitalize;
    font-size: 18px;
    font-family: "gordita_medium";
`;
const Sentence = styled.p`
    color: #fff;
    font-size: 14px;
    width: 72%;
    margin: 12px 0 36px;
    font-family: gordita_regular;
    @media all and (max-width: 980px) {
        width: 97%;
    }
`;
const RightContainer = styled.div`
    width: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    @media all and (max-width: 768px) {
        width: 100%;
    }
`;
const LogoDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LogoContainer = styled.div`
    width: 12%;
`;
const LogoIcon = styled.img`
    width: 100%;
    display: block;
`;
const Small = styled.span`
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    font-size: 24px;
    font-family: "gordita_bold";
    color: #247d3d;
`;
const Color = styled.a`
    color: #1a987c;
    font-family: "gordita_medium";
    margin-left: 2px;
    text-decoration:none
`;
const Text = styled.h3`
    font-size: 14px;
    color: #4d4d4d;
    font-family: "gordita_medium";
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 5px;
`;
const Paragraph = styled.p`
    margin: 15px 0 60px;
    font-size: 16px;
    text-align: center;
    color: #747474;
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const DivInput = styled.div`
    position: relative;
    p {
        position: absolute;
        top: 235px;
        z-index: 99;
        font-size: 12px;
        font-family: "gordita_medium";
        color: #ff3434;
    }
`;
const LabelOne = styled.label`
    top: -20px;
    color: #4d4d4d;
    font-size: 12px;
    position: absolute;
    left: 7px;
    display: none;
    transition-delay: 0.1s;
    transition: width 2s ease-in-out;
    &.active {
        display: block;
        transition: width 2s ease-in-out;
        transition-delay: 0.1s;
    }
`;
const LabelTwo = styled.label`
    top: -20px;
    color: #4d4d4d;
    font-size: 12px;
    position: absolute;
    left: 7px;
    display: none;
    transition-delay: 0.1s;
    &.active {
        display: block;
        transition-delay: 0.1s;
    }
`;

const InputContainer = styled.div`
    height: 45px;
    width: 100%;
    background: #f4f5fc;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    position: relative;
    transition-delay: 0.1s;
    &.active {
        border: 1px solid #247d3d;
        background: #fff;
        transition-delay: 0.1s;
    }
`;
const InputField = styled.input`
    width: 90%;
    color: #000;
    &.username {
        color: #000;
    }
    &.password {
        color: #000;
    }
`;
const InputOne = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    height: 45px;
    width: 100%;
    background: #f4f5fc;
    border-radius: 5px;
    padding: 10px;
    position: relative;
    transition-delay: 0.1s;
    &.active {
        border: 1px solid #247d3d;
        background: #fff;
        transition-delay: 0.1s;
    }
`;
const Eyespan = styled.div`
    width: 20px;
    @media all and (max-width: 480px) {
        width: 23px;
    }
    @media all and (max-width: 360px) {
        width: 30px !important;
    }
`;
const Eye = styled.img`
    width: 100%;
    display: block;
    &.show {
        display: none;
    }
`;
const EyeHidden = styled.img`
    width: 100%;
    display: none;
    &.deactive {
        display: block;
    }
`;
const DivSign = styled.div`
    display: flex;
    margin-top: 40px;
`;
const CheckBox = styled.div`
    color: #89939e;
    height: 18px;
    width: 18px;
    border-radius: 3px;
    &.active {
        border: 1px solid #247d3d;
    }
`;
const Tick = styled.img`
    width: 100%;
    display: block;
    &.active {
        display: none;
    }
`;
const Span = styled.span`
    display: inline-block;
    font-size: 14px;
    font-family: "gordita_medium";
    margin-left: 12px;
    color: #212121;
`;
const Button = styled.div`
    width: 100%;
    height: 50px;
    background: #247d3d;
    color: #ffff;
    font-size: 14px;
    font-family: "gordita_regular";
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-top: 82px;
    cursor: pointer;
    @media all and (max-width: 1080px) {
        height: 45px;
    }
    @media all and (max-width: 980px) {
        height: 40px;
    }
    @media all and (max-width: 768px) {
        margin-bottom: 40px;
        height: 50px;
    }
    @media all and (max-width: 480px) {
        height: 40px;
    }
`;
