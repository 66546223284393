import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/style.css";
import styled from "styled-components";
import { Link } from "react-router-dom";
//components
import SectionLoader from "../includes/loaders/SectionLoader";
import DataNotFound from "./DataNotFound";
import PlaceHolder from "../general/PlaceHolder";
import TechiesParkHelmet from "../helpers/TechiesParkHelmet";

export default function Dashboard({ techiesparkData, isLoading }) {
    const [isHovered, setHovered] = useState("");
    //image loading
    const [imageLoading, setImageLoading] = useState(true);
    function onLoad() {
        setImageLoading(false);
    }
    return (
        <div>
            <TechiesParkHelmet title="Techiespark" />
            <Container id="main">
                <DivMain>
                    <Text>
                        Techies parks (<Span>{techiesparkData.length}</Span>)
                    </Text>
                    {isLoading ? (
                        <Cover>
                            <SectionLoader />
                        </Cover>
                    ) : (
                        <>
                            {techiesparkData.length != 0 ? (
                                <DivContainer>
                                    {techiesparkData.map((item) => (
                                        <Div
                                            key={item.id}
                                            to={`/techies-park/${item.slug}/`}
                                            target="_blank"
                                            onMouseEnter={() =>
                                                setHovered(item.slug)
                                            }
                                            onMouseLeave={() => setHovered("")}
                                        >
                                            <ImageContainer>
                                                <PlaceHolder
                                                    isLoading={imageLoading}
                                                    paddingTop="61.5%"
                                                />
                                                <Image
                                                    style={{
                                                        display: imageLoading
                                                            ? "none"
                                                            : "block",
                                                    }}
                                                    className={
                                                        isHovered == item.slug
                                                            ? "active-hover"
                                                            : ""
                                                    }
                                                    src={item.thumbnail_image}
                                                    onLoad={onLoad}
                                                    alt="Image"
                                                />
                                            </ImageContainer>
                                            <DivTop>
                                                <Sentence>
                                                    {item.campus}
                                                </Sentence>
                                                <DivLocation>
                                                    <Location
                                                        src={
                                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/locationicon.svg"
                                                        }
                                                        alt="image"
                                                    />
                                                    <Small>
                                                        {item.location}
                                                    </Small>
                                                </DivLocation>
                                            </DivTop>
                                        </Div>
                                    ))}
                                </DivContainer>
                            ) : (
                                <DataNotFound />
                            )}
                        </>
                    )}
                </DivMain>
            </Container>
        </div>
    );
}

const Container = styled.div`
    padding: 100px 20px 0px 200px;
    height: 100vh;
    @media all and (max-width: 1500px) {
        padding: 100px 20px 0px 90px;
    }
    @media all and (max-width: 980px) {
        padding: 100px 20px 0px 90px;
    }
    @media all and (max-width: 768px) {
        padding: 100px 20px 0px 90px;
    }
    @media all and (max-width: 640px) {
        padding: 100px 20px 0px 82px;
    }
    @media all and (max-width: 480px) {
        padding: 80px 0px 0px 0px;
    }
`;
const DivMain = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    height: 90vh;
`;
const Text = styled.div`
    font-size: 18px;
    display: inline-block;
    font-family: "gordita_medium";
    margin-bottom: 15px;
`;
const Span = styled.span`
    color: #0fa76f;
`;
const DivContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    flex-wrap: wrap;
    grid-gap: 20px;
    padding-bottom: 50px;
    @media all and (max-width: 1080px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media all and (max-width: 980px) {
        grid-template-columns: 1fr 1fr;
    }
    @media all and (max-width: 540px) {
        grid-template-columns: 1fr;
    }
`;
const Div = styled(Link)`
    border: 1px solid #eeeeee;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    animation: 300ms ease-in-out 0ms 1 normal none running fade;
    @keyframes fade {
        0% {
            opacity: 0;
            transform: scale(0.9);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    &:hover {
        opacity: 1 !important;
        border: 1px solid #0fa76f;
    }
    @media all and (max-width: 1280px) {
    }
    @media all and (max-width: 1080px) {
    }
    @media all and (max-width: 540px) {
    }
`;
const ImageContainer = styled.div`
    width: 100%;
    overflow: hidden;
`;
const Image = styled.img`
    width: 100%;
    display: block;
    border-radius: 3px;
    margin-bottom: 10px;
    transition: 0.2s ease-in-out;
    &.active-hover {
        transform: scale(1.1);
        transition: 0.2s ease-in-out;
    }
`;

const Sentence = styled.p`
    color: #000;
    font-size: 14px;
    min-height: 55px;
    font-family: "gordita_medium";
    margin-bottom: 5px;
    text-transform: capitalize;
`;
const DivTop = styled.div`
    padding: 10px;
`;
const DivLocation = styled.div`
    display: flex;
    width: 17px;
    margin-bottom: 5px;
    align-items: center;
`;
const Location = styled.img`
    width: 100%;
    display: block;
`;
const Small = styled.span`
    color: #0fa76f;
    font-size: 12px;
    display: inline-block;
    margin-left: 10px;
    font-family: "gordita_medium";
    transform: translateY(1px);
    text-transform: capitalize;
`;
const Cover = styled.div`
    height: 70vh;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`;
