import React from "react";
import styled from "styled-components";
//components
import EventCard from "../../includes/events/EventCard";

export default function SimliarMoments({ eventDatas }) {
    const length = eventDatas.length - 1;
    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }
    const len = getRandomInt(length);

    return (
        <MainConatiner>
            <div className="wrapper">
                <TopSection>
                    <Title>
                        Similar <Span>Events </Span>
                    </Title>
                </TopSection>
                <BottomContainer>
                    <MainList>
                        {eventDatas &&
                            eventDatas?.map((item, index) => (
                                <>
                                    {index <= 2 && (
                                        <EventCard item={item} index={index} />
                                    )}
                                </>
                            ))}
                    </MainList>
                </BottomContainer>
            </div>
        </MainConatiner>
    );
}
const MainConatiner = styled.div`
    padding: 100px 0px;
    position: relative;
    background: #fcfbf8;
    height: 100%;
    @media all and (max-width: 980px) {
        padding-left: 0px;
        padding: 80px 0px;
    }
    @media all and (max-width: 768px) {
        padding: 70px 0px;
    }
    @media all and (max-width: 640px) {
        padding: 50px 0px;
    }
`;
const MainList = styled.ul`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    @media all and (max-width: 1080px) {
        grid-template-columns: 1fr 1fr;
    }
    @media all and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;
const TopSection = styled.div`
    margin-bottom: 30px;
`;
const Title = styled.h2`
    color: #000;
    font-size: 28px;
    font-family: gordita_medium;
    @media all and (max-width: 768px) {
        font-size: 26px;
        text-align: center;
    }
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
`;
const Span = styled.span`
    color: #62af84;
    font-size: 28px;
    font-family: gordita_medium;
    position: relative;
    @media all and (max-width: 768px) {
        font-size: 26px;
    }
    @media all and (max-width: 480px) {
        font-size: 18px;
    }

    &::before {
        content: "";
        background-image: url("https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/green-layer.png");
        width: 70%;
        height: 100%;
        position: absolute;
        background-size: contain;
        top: 35px;
        left: 0;
        background-repeat: no-repeat;
        right: 0;
        margin: 0 auto;
    }
`;
const BottomContainer = styled.div`
    .paginationButton {
        display: flex;
        gap: 10px;
        margin-top: 50px;
        justify-content: center;
    }
    .paginationButton a {
        border: 1px solid #0fa76f;
        width: 90%;
        display: flex;
        font-family: gordita_medium;
        font-size: 15px;
        padding: 10px;
        margin-right: 20px;
        border-radius: 50%;
        color: #0fa76f;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
            background-color: #0fa76f;
            color: #fff;
        }
    }
    .previousButton {
        display: none !important;
    }
    .paginationActive {
    }
    .nextButton {
        padding: 8px !important;
        width: 44px !important;
        &:hover {
            background-color: #fff !important;
        }
    }
`;
