import React, { useState, useEffect, useContext, Suspense, lazy } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../contexts/Store";
import SectionLoader from "../../helpers/SectionLoader";
import AuthRoute from "../routes/AuthRoute";
import PrivateRoute from "../routes/PrivateRoute";
import AppRouter from "./AppRouter";
import AuthRouter from "./authentication/AuthRouter";

function MainRouter() {
    const { dispatch } = useContext(Context);

    const [isLoading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);

    useEffect(() => {
        async function fetchUserData() {
            let promise = new Promise((resolve, reject) => {
                let user_data = localStorage.getItem("user_data");
                user_data = JSON.parse(user_data);

                dispatch({
                    type: "UPDATE_USER_DATA",
                    user_data: { ...user_data, is_loading: true },
                });

                setTimeout(() => {
                    resolve("done!");
                    setLoading(false);
                    dispatch({
                        type: "UPDATE_USER_DATA",
                        user_data: { is_loading: false },
                    });
                }, 500);
            });

            let result = await promise;
        }

        fetchUserData();
    }, []);

    useEffect(() => {
        async function fetchEventsData() {
            let promise = new Promise((resolve, reject) => {
                let events_data = localStorage.getItem("events_data");
                events_data = JSON.parse(events_data);

                dispatch({
                    type: "UPDATE_EVENTS_DATA",
                    events_data: events_data,
                });

                setTimeout(() => {
                    resolve("done!");
                    setLoading(false);
                }, 500);
            });

            let result = await promise;
        }

        fetchEventsData();
    }, []);

    useEffect(() => {
        dispatch({
            type: "UPDATE_SEARCH_PARAMS",
            payload: currentParams,
        });
    }, []);

    return (
        <Suspense
            fallback={
                <Cover>
                    <SectionLoader />
                </Cover>
            }
        >
            <Routes>
                <Route
                    path="auth/*"
                    element={
                        <AuthRoute>
                            <AuthRouter />
                        </AuthRoute>
                    }
                />
                <Route
                    path="/*"
                    element={
                        <PrivateRoute>
                            <AppRouter />
                        </PrivateRoute>
                    }
                />
            </Routes>
        </Suspense>
    );
}

export default MainRouter;
const Cover = styled.div`
    height: 100vh;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`;
