import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import SectionLoader from "../../includes/loaders/SectionLoader";
import EventsLandingPage from "../../screens/events/EventslandingPage";
import EventsListPage from "../../screens/events/EventsListPage";

function EventsRouter() {
    return (
        <Suspense
            fallback={
                <Cover>
                    <SectionLoader />
                </Cover>
            }
        >
            <Routes>
                <Route path="/" element={<EventsListPage />} />
                <Route path="view/:event_id/" element={<EventsLandingPage />} />
            </Routes>
        </Suspense>
    );
}

export default EventsRouter;

const Container = styled.div`
    padding-top: 90px;
`;
const Cover = styled.div`
    height: 100vh;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`;
