import React from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import styled from "styled-components";
import { Link as scrollLink } from "react-router-dom";

export default function EventsHeader() {
    const { techiespark_id } = useParams();
    return (
        <Container>
            <div className="wrapper">
                <Goback to={`/techies-park/${techiespark_id}/`}>
                    <LinkTag>
                        <img
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/go_back_icon.svg"
                            }
                            alt="Arrow"
                        />
                    </LinkTag>
                </Goback>
                <Logo>
                    <LogoTag to={`/techies-park/${techiespark_id}/`}>
                        <img
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/Techiespark_Logo.png"
                            }
                            alt="Logo"
                        />
                    </LogoTag>
                </Logo>
            </div>
        </Container>
    );
}

const Container = styled.div`
    min-height: 100px;
    width: 100%;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    z-index: 1000;
    & .wrapper {
        display: flex;
        align-items: center;
    }
`;
const Goback = styled(Link)`
    //   width: 16%;
    border: 1px solid #0fa76f;

    padding: 10px 20px;
    border-radius: 3px;
    border-radius: 6px;
    @media all and (max-width: 768px) {
        padding: 8px 28px;
    }

    @media all and (max-width: 980px) {
        // width: 12px;
    }
    @media all and (max-width: 768px) {
        // width: 10px;
    }
`;
const H1 = styled.h1`
    font-size: 20px;
    font-family: gordita_bold;
    color: #247d3d;
    text-transform: uppercase;
    margin-left: 30px;
`;
const LinkTag = styled.div`
    width: 20px;
    img {
        display: block;
        width: 100%;
    }
`;
const Nav = styled.nav`
    display: flex;

    @media all and (max-width: 768px) {
        display: none;
    }
`;
const NavItem = styled(NavLink)`
    font-size: 18px;
    color: #666666;
    font-family: "gordita_medium";
    margin-right: 50px;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }

    &.active {
        color: #0fa76f;
    }

    &:hover {
        color: #0fa76f;
    }
`;
const Hamburger = styled.div`
    width: 50px;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    cursor: pointer;
    display: none;
    @media all and (max-width: 768px) {
        display: flex;
    }
`;
const LineSpan = styled.span`
    width: ${({ type, isModal }) =>
        !isModal ? (type === "middle" ? "35px" : "45px") : "40px"};
    height: 5px;
    background: ${({ type }) => (type === "last" ? "#96CA4C" : "#18484C")};
    margin-bottom: 8px;
    border-radius: 30px;
    &:first-child {
        transform: ${({ isModal }) =>
            isModal ? "translateY(13px) rotate(45deg)" : "rotate(0deg)"};
        transition: all 0.5s ease;
    }
    &:nth-child(2) {
        transform: ${({ isModal }) =>
            isModal ? "translateX(-12px)" : "translateX(0px)"};
        opacity: ${({ isModal }) => (isModal ? "0" : "1")};
        transition: all 0.5s ease;
    }
    &:nth-child(3) {
        transform: ${({ isModal }) =>
            isModal ? "translateY(-13px) rotate(-45deg)" : "rotate(0deg)"};
        transition: all 0.5s ease;
    }
    transition: all 0.5s ease;
    @media all and (max-width: 960px) {
        width: ${({ type, isModal }) =>
            !isModal ? (type === "middle" ? "28px" : "38px") : "33px"};
        margin-bottom: 5px;
        &:first-child {
            transform: ${({ isModal }) =>
                isModal ? "translateY(10px) rotate(45deg)" : "rotate(0deg)"};
            transition: all 0.5s ease;
        }
        &:nth-child(2) {
            transition: all 0.5s ease;
        }
        &:nth-child(3) {
            transform: ${({ isModal }) =>
                isModal ? "translateY(-10px) rotate(-45deg)" : "rotate(0deg)"};
            transition: all 0.5s ease;
        }
    }
    @media all and (max-width: 540px) {
        width: ${({ type, isModal }) =>
            !isModal ? (type === "middle" ? "22px" : "30px") : "28px"};
        margin-bottom: 5px;
        &:first-child {
            transform: ${({ isModal }) =>
                isModal ? "translateY(10px) rotate(45deg)" : "rotate(0deg)"};
            transition: all 0.5s ease;
        }
        &:nth-child(2) {
            transition: all 0.5s ease;
        }
        &:nth-child(3) {
            transform: ${({ isModal }) =>
                isModal ? "translateY(-10px) rotate(-45deg)" : "rotate(0deg)"};
            transition: all 0.5s ease;
        }
    }
`;
const Logo = styled.h1`
    width: 16%;
    margin-left: 20px;

    @media all and (max-width: 980px) {
        width: 20%;
    }
    @media all and (max-width: 768px) {
        width: 28%;
    }
    @media all and (max-width: 640px) {
        width: 35%;
    }
    @media all and (max-width: 480px) {
        width: 44%;
    }
`;
const LogoTag = styled(scrollLink)`
    font-size: 28px;
    font-family: "gordita_bold";
    color: #247d3d;
    img {
        display: block;
        width: 100%;
    }
`;
// const SliderMenuBox = styled.div`
//     display: flex;
//     justify-content: space-between;
//     // display: grid;
//     // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
// `;
// const SliderMenu = styled.div`
//   width: 80px;
//   height: 50px;
//   margin-right:5px
//   background: blue;
//   cursor:pointer;
// `;
