import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Context } from "../../contexts/Store";
//axios
import { techiesparkConfig } from "../../../axiosConfig";
//components
import Video from "../../includes/modal/Video";
import SectionLoader from "../../includes/loaders/SectionLoader";
import PlaceHolder from "../../general/PlaceHolder";

function Spotlight() {
    const { techiespark_id } = useParams();
    const [isModal, setModal] = useState(false);
    const [modalType, setModalType] = useState(false);
    const [data, setData] = useState({});
    const [isLoading, setLoading] = useState(true);
    //image loading
    const [imageLoading, setImageLoading] = useState(true);
    function onLoad() {
        setImageLoading(false);
    }

    const {
        state: {
            user_data: { access_token },
        },
        dispatch,
    } = useContext(Context);

    useEffect(() => {
        techiesparkConfig
            .get(`techies-parks/view/${techiespark_id}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then(function (response) {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setData(data);
                    setLoading(false);
                } else if (StatusCode === 6001) {
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: "UPDATE_ERROR",
                    error: err,
                    errorMessage: "Server error please try again",
                });
            });
    }, []);

    return (
        <div>
            <MainContainer id="home">
                <Video
                    isModal={isModal}
                    setModal={setModal}
                    modalType={modalType}
                    videoUrl={data.video_url}
                />
                {isLoading ? (
                    <Cover>
                        <SectionLoader />
                    </Cover>
                ) : (
                    <div className="wrapper">
                        <LeftContainer>
                            <Heading>
                                Now we are at
                                <Span> {data.location}</Span> <br />
                                Building a Strong <br /> Startup Ecosystem
                            </Heading>
                            <Heading className="active">
                                Now we are at
                                <Span> {data.location} </Span>
                                Building a Strong Startup Ecosystem
                            </Heading>
                            <Paragraph>{data.description}</Paragraph>
                            <DivBox>
                                <ImageDiv>
                                    <Image
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/location-white.svg"
                                        }
                                        alt="image"
                                    />
                                </ImageDiv>
                                <Text>{data.campus}</Text>
                            </DivBox>
                            <Button
                                onClick={() => {
                                    setModal(true);
                                    setModalType(true);
                                }}
                            >
                                Play Video
                                <Play>
                                    <Icon
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/play.svg"
                                        }
                                        alt="image"
                                    />
                                </Play>
                            </Button>
                        </LeftContainer>
                        <RightContainer>
                            <RightInnerContainer>
                                <PlaceHolder
                                    isLoading={imageLoading}
                                    paddingTop="60%"
                                    width="45%"
                                />
                                <ImageContainer>
                                    <img
                                        style={{
                                            display: imageLoading
                                                ? "none"
                                                : "block",
                                        }}
                                        src={data.spotlight_image}
                                        onLoad={onLoad}
                                        alt="image"
                                    />
                                </ImageContainer>

                                <PlayBtn
                                    onClick={() => {
                                        setModal(true);
                                        setModalType(true);
                                    }}
                                >
                                    <PlayBtnInnerContainer>
                                        <PlayBtnImgBox className="play-icon-img">
                                            <PlayBtnImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/green-playbtn.svg"
                                                }
                                                alt="play-icon"
                                            />
                                        </PlayBtnImgBox>
                                    </PlayBtnInnerContainer>
                                </PlayBtn>
                            </RightInnerContainer>
                        </RightContainer>
                    </div>
                )}
            </MainContainer>
        </div>
    );
}

const MainContainer = styled.div`
    padding-top: calc(10% + 80px);
    background: linear-gradient(284.92deg, #f4f1e9 0%, #ffffff 98.38%);
    animation: 300ms ease-in-out 0ms 1 normal none running fade;
    @keyframes fade {
        0% {
            opacity: 0;
            transform: scale(0.9);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    & .wrapper {
        display: flex;
        position: relative;
        @media all and (max-width: 980px) {
            flex-wrap: wrap;
            flex-direction: column-reverse;
        }
    }
    @media all and (max-width: 640px) {
        padding-top: calc(15% + 80px);
    }
    @media all and (max-width: 360px) {
        padding-top: calc(16% + 80px);
    }
`;

const LeftContainer = styled.div`
    width: 81%;
    padding-bottom: 100px;
    @media (max-width: 1200px) {
        padding-bottom: 60px;
    }
    @media all and (max-width: 1080px) {
        width: 70%;
    }
    @media all and (max-width: 980px) {
        width: 100%;
    }
    @media all and (max-width: 900px) {
        width: 100%;
    }
`;
const Heading = styled.h1`
    font-size: 54px;
    font-family: "gordita_bold";
    margin-bottom: 20px;
    &.active {
        display: none;
        @media all and (max-width: 980px) {
            text-align: center;
            width: 80%;
            margin: 0 auto 20px;
            display: block;
        }
        @media all and (max-width: 768px) {
            width: 100%;
        }
        @media all and (max-width: 640px) {
            font-size: 28px;
        }
        @media all and (max-width: 480px) {
            font-size: 25px;
        }
    }
    @media all and (max-width: 1400px) {
        font-size: 40px;
    }
    @media all and (max-width: 1280px) {
        font-size: 34px;
    }
    @media all and (max-width: 1080px) {
        font-size: 32px;
    }
    @media all and (max-width: 980px) {
        text-align: center;
        width: 80%;
        margin: 0 auto 20px;
        display: none;
    }
    @media all and (max-width: 768px) {
        width: 100%;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 25px;
    }
`;
const Paragraph = styled.p`
    color: #747474;
    font-size: 16px;
    width: 50%;
    margin-bottom: 50px;
    @media (max-width: 1200px) {
        margin-bottom: 35px;
        width: 55%;
    }
    @media all and (max-width: 1080px) {
        width: 85%;
    }
    @media all and (max-width: 980px) {
        text-align: center;
        width: 100%;
    }
    @media all and (max-width: 640px) {
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 13px;
    }
`;
const Span = styled.span`
    color: #0fa76f;
    display: inline;
    font-size: inherit;
    font-family: "gordita_bold";
    font-style: italic;
    @media all and (max-width: 980px) {
        /* font-size: 34px; */
    }
    @media all and (max-width: 480px) {
        /* font-size: 28px; */
    }
`;
const DivBox = styled.div`
    display: flex;
    margin-bottom: 45px;
    border: 1px solid #eeeeee;
    width: fit-content;
    background: #fff;
    align-items: center;
    font-size: 14px;
    font-size: 12px;
    justify-content: space-evenly;
    padding: 12px;
    border-radius: 8px;
    @media (max-width: 1200px) {
        margin-bottom: 35px;
    }
    @media all and (max-width: 980px) {
        margin: 0 auto 20px;
    }
    @media all and (max-width: 640px) {
        width: 70%;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
    @media all and (max-width: 360px) {
        width: auto;
    }
`;
const ImageDiv = styled.div`
    width: 18px;
    @media all and (max-width: 640px) {
        width: 25px;
        margin-right: 10px;
    }
    @media all and (max-width: 480px) {
        width: 35px;
        margin-right: 0px;
    }
`;
const Image = styled.img`
    width: 100%;
    display: block;
`;
const Text = styled.span`
    display: inline-block;
    font-size: 14px;
    font-family: "gordita_medium";
    margin-left: 5px !important;
    @media all and (max-width: 480px) {
        font-size: 13px;
        text-align: left;
    }
`;
const Button = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: 18px 45px;
    margin-top: 40px;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    cursor: pointer;
    transition: all 0.4s ease-in;
    background: #0fa76f;
    opacity: 0.9;
    &:hover {
        background-color: #0c7d54;
        transition: all 0.4s ease-in;
        opacity: 1;
    }
    @media all and (max-width: 980px) {
        margin: 0 auto;
    }
    @media all and (max-width: 640px) {
        padding: 15px 40px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
        padding: 14px 35px;
    }
`;
const Icon = styled.img`
    width: 100%;
    display: block;
`;
const RightContainer = styled.div`
    width: 62%;
    position: absolute;
    right: -8%;
    bottom: 0;
    @media all and (max-width: 1080px) {
        right: -10%;
    }
    @media all and (max-width: 980px) {
        width: 60%;
        position: unset;
    }

    @media all and (max-width: 980px) {
        margin: 0 auto 30px;
    }
    @media all and (max-width: 640px) {
        width: 70%;
    }
    @media all and (max-width: 580px) {
        width: 75%;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
    @media all and (max-width: 360px) {
        width: 100%;
    }
`;
const RightInnerContainer = styled.div`
    position: relative;
`;
const ImageContainer = styled.div`
    width: 100%;
    img {
        width: 100%;
        display: block;
        height: 550px;
        @media all and (max-width: 1400px) {
            height: 550px;
        }
        @media all and (max-width: 1280px) {
            height: 500px;
        }
        @media all and (max-width: 1200px) {
            height: 460px;
        }
        @media all and (max-width: 1080px) {
            height: 430px;
        }
        @media all and (max-width: 980px) {
            height: 400px;
        }
        @media all and (max-width: 900px) {
            height: 350px;
        }
        @media all and (max-width: 768px) {
            height: 300px;
        }
        @media all and (max-width: 480px) {
            height: 260px;
        }
        @media all and (max-width: 360px) {
            height: 250px;
        }
    }
`;

const Play = styled.div`
    width: 20px;
    margin-left: 10px;
`;

const PlayBtn = styled.div`
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
`;
const PlayBtnInnerContainer = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    z-index: 1000;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 50%;
        transition: 2s;
    }
    &::after {
        background-color: #0fa76f;
        opacity: 0.4;
        z-index: -1;
        animation: pulseouter 2s infinite;
    }

    @keyframes pulseouter {
        0% {
            transform: scale(2);
        }
        100% {
            transform: scale(1.4);
        }
    }
    @keyframes pulseinner {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.08);
        }
    }
    @media all and (max-width: 980px) {
        width: 70px;
        height: 70px;
    }
    @media all and (max-width: 480px) {
        width: 60px;
        height: 60px;
    }
    @media all and (max-width: 360px) {
        width: 50px;
        height: 50px;
    }
`;
const PlayBtnImgBox = styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    /* animation: pulseinner 2s infinite; */
`;
const PlayBtnImg = styled.img`
    display: block;
    width: 100%;
`;
const Cover = styled.div`
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default Spotlight;
