import React from "react";
import Lottie from "react-lottie";
import loader from "../../../assets/lottie/loader.json";
import styled from "styled-components";

export default function SectionLoader() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loader,
        rendererSettings: {},
    };
    return (
        <Lottie options={defaultOptions} height={130} width={130} speed={2} />
    );
}
