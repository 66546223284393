import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import PlaceHolder from "../../general/PlaceHolder";
import SectionLoader from "../../includes/loaders/SectionLoader";

export default function EventsSinglePage({ data, event_no }) {
    //image loading
    const [imageLoading, setImageLoading] = useState(true);
    function onLoad() {
        setTimeout(() => {
            setImageLoading(false);
        }, 1500);
    }

    useEffect(() => {
        var element = document.getElementById("eventSingle");
        element?.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    }, []);

    //remove tags from the description
    let description = data?.description?.replace(/\n/g, "<br />");

    const markupDescription = () => {
        return {
            __html: description,
        };
    };

    return (
        <MainContianer id="eventSingle">
            <Container className="wrapper">
                <LeftSection>
                    <LeftContainerTop>
                        <Title>{data?.title}</Title>
                    </LeftContainerTop>
                    <LeftBottom>
                        {event_no && (
                            <Number>
                                {event_no <= 9 && "0"}
                                {event_no}
                            </Number>
                        )}
                        <Description
                            dangerouslySetInnerHTML={markupDescription()}
                        />
                    </LeftBottom>
                </LeftSection>
                <RightSection>
                    <RightContainer>
                        <GreenBox></GreenBox>
                        <ImageContainer>
                            <PlaceHolder
                                isLoading={imageLoading}
                                paddingTop="61.5%"
                            />
                            <Img
                                style={{
                                    display: imageLoading ? "none" : "block",
                                }}
                                src={data.spotlight_image}
                                alt="Events Image"
                                onLoad={onLoad}
                            />
                        </ImageContainer>
                    </RightContainer>
                </RightSection>
            </Container>
        </MainContianer>
    );
}
const MainContianer = styled.div`
    padding: 200px 0px 80px 0;
    background: #fcfbf8;
    // padding-left: 60px;
    animation: 300ms ease-in-out 0ms 1 normal none running fade;
    @keyframes fade {
        0% {
            opacity: 0;
            transform: scale(0.9);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    @media all and (max-width: 1200px) {
        padding: 200px 0px 30px 0;
    }
    @media all and (max-width: 1080px) {
        padding: 200px 0px 30px 0;
    }
    @media all and (max-width: 480px) {
        padding: 150px 0px 0px 0;
    }
`;
const Container = styled.div`
    display: flex;
    // width: 100%;
    @media all and (max-width: 980px) {
        flex-direction: column-reverse;
        // width: 90%;
        margin: 0 auto;
        max-width: 1325px;
    }
`;
const LeftSection = styled.div`
    width: 50%;
    // margin-left: 10px;
    @media all and (max-width: 1080px) {
        width: 62%;
    }
    @media all and (max-width: 980px) {
        margin-top: 30px;
        width: 80%;
        margin: 40px auto 0 auto;
    }
    @media all and (max-width: 768px) {
        width: 90%;
        margin: 40px auto 0 auto;
    }
    @media all and (max-width: 640px) {
        width: unset;
    }
`;
const LeftContainerTop = styled.div``;

const Title = styled.h4`
    font-family: gordita_medium;
    line-height: 1.5;
    width: unset;
    font-size: 28px;
    @media all and (max-width: 1080px) {
        font-size: 25px;
    }
    @media all and (max-width: 980px) {
        text-align: center;
    }
    @media all and (max-width: 640px) {
        font-size: 20px;
    }
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
`;
const LeftBottom = styled.div`
    margin-top: 20px;
    padding-left: 35px;
    width: 90%;
    @media all and (max-width: 640px) {
        padding-left: 0px;
    }
`;

const Number = styled.h5`
    color: #0fa76f;
    font-size: 18px;
    font-family: gordita_medium;
    margin-bottom: 10px;
`;
const Description = styled.p`
    font-size: 16px !important;
    font-family: gordita_regular;
    color: #5a5a5a;
    @media all and (max-width:1400px){
        p {
            font-size: 14px !important;
        }
    }
    strong {
        color: #000000;
        font-size: 16px;
        font-family: gordita_medium;
        @media all and (max-width: 980px) {
            text-align: center;
        }
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
    @media all and (max-width: 980px) {
        text-align: center;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;

const RightSection = styled.div`
    width: 50%;
    margin-left: 30px;
    @media all and (max-width: 1080px) {
        margin-left: 48px;
    }
    @media all and (max-width: 980px) {
        width: 80%;
        margin: 0px auto;
    }
    @media all and (max-width: 768px) {
        width: 85%;
    }
    @media all and (max-width: 480px) {
        width: unset;
    }
`;
const RightContainer = styled.div`
    position: relative;
    // z-index: 1;
`;
const GreenBox = styled.div`
    border: 1px solid #0fa76f;
    width: 108%;
    height: 95%;
    position: absolute;
    top: -45px;
    left: 9px;
    border-radius: 30px;
    border-radius: 30px;
    @media all and (max-width: 980px) {
        display: none;
    }
`;
const ImageContainer = styled.div`
    position: relative;
`;
const Img = styled.img`
    display: block;
    width: 100%;
    border-radius: 30px;
`;
