import React, { useState } from "react";
import styled from "styled-components";
//components
import EventModalHeader from "../EventModalHeader";
import PlaceHolder from "../../../general/PlaceHolder";

function SingleEventModal({
    selectedId,
    gallery,
    setSelectedId,
    selectedImage,
    setSelectedImage,
    isModal,
    setModal,
    setCurrentIndex,
    currentIndex,
}) {
    //image loading
    const [imageLoading, setImageLoading] = useState(true);
    function onLoad() {
        setTimeout(() => {
            setImageLoading(false);
        }, 1500);
    }
    return (
        isModal && (
            <>
                <EventModalHeader
                    gallery={gallery}
                    setSelectedId={setSelectedId}
                    selectedId={selectedId}
                    setSelectedImage={setSelectedImage}
                    selectedImage={selectedImage}
                    setModal={setModal}
                />
                <MainContainer className={selectedId ? "active" : ""}>
                    <ModalContainer>
                        {gallery.map((item, index) => {
                            return (
                                <>
                                    {selectedId === item.id && (
                                        <ImageBox>
                                            {currentIndex > 0 && (
                                                <span
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        setCurrentIndex(
                                                            currentIndex - 1
                                                        );
                                                        setSelectedId(
                                                            gallery[index - 1]
                                                                .id
                                                        );
                                                        setSelectedImage(
                                                            gallery[index - 1]
                                                                .image
                                                        );
                                                    }}
                                                >
                                                    <div className="left">
                                                        <img
                                                            src={
                                                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/left arrow.svg"
                                                            }
                                                            alt="Left-arrow"
                                                        />
                                                    </div>
                                                </span>
                                            )}
                                            <PlaceHolder
                                                isLoading={imageLoading}
                                                width="100%"
                                            />
                                            <Image
                                                style={{
                                                    display: imageLoading
                                                        ? "none"
                                                        : "block",
                                                }}
                                                src={selectedImage}
                                                onLoad={onLoad}
                                                alt="image"
                                            />
                                            {currentIndex <
                                                gallery.length - 1 && (
                                                <span
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        setCurrentIndex(
                                                            currentIndex + 1
                                                        );
                                                        setSelectedId(
                                                            gallery[index + 1]
                                                                .id
                                                        );
                                                        setSelectedImage(
                                                            gallery[index + 1]
                                                                .image
                                                        );
                                                    }}
                                                >
                                                    <div className="right">
                                                        <img
                                                            src={
                                                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/right arrow.svg"
                                                            }
                                                            alt="Right-arrow"
                                                        />
                                                    </div>
                                                </span>
                                            )}
                                        </ImageBox>
                                    )}
                                </>
                            );
                        })}
                    </ModalContainer>
                </MainContainer>
            </>
        )
    );
}

const MainContainer = styled.div`
    position: fixed;
    transition: 0.8s;
    transform: scale(0, 0);
    background: #fafafa;
    z-index: 1001;

    &.active {
        transition: 0.3s;
        width: 100%;
        height: 100vh;
        transform: scale(1, 1);
        transition: 0.8s;
        left: 0;
        top: 0;
        bottom: 0;
        opacity: 1;
    }
`;
const ModalContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding-top: 100px;
    @media all and (max-width: 768px) {
        padding-top: 0px;
    }
`;
const ImageBox = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    position: relative;
    @media all and (max-width: 1080px) {
        width: 60%;
    }
    @media all and (max-width: 980px) {
        width: 75%;
    }
    @media all and (max-width: 768px) {
        width: 70%;
    }
    @media all and (max-width: 480px) {
        width: 70%;
    }
    div {
        width: 70%;
        &.right {
            width: 60px;

            position: absolute;
            right: -70px;
            @media all and (max-width: 640px) {
                width: 50px;
                right: -55px;
            }
            @media all and (max-width: 480px) {
                width: 40px;
                right: -45px;
            }
            @media all and (max-width: 360px) {
                width: 30px;
            }
        }
        &.left {
            width: 60px;
            position: absolute;
            left: -70px;
       
            @media all and (max-width: 640px) {
                width: 50px;
                left: -55px;
            }
            @media all and (max-width: 480px) {
                width: 40px;
                left: -45px;
            }
            @media all and (max-width: 360px) {
                width: 30px;
            }
        }
    }
    img {
        width: 100%;
        display: block;
    }
`;
const Image = styled.img`
    display: block;
    width: 100%;
    object-fit: cover;
`;
const RightContainer = styled.h4``;
export default SingleEventModal;
