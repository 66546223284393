import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
    user_data: {
        is_verified: false,
        access_token: "",
        name: "",
    },
    isSideBar: false,
    currentParams: {},
    galleryData: [],
    isModal: false,
    events_data: {
        event_no: "",
    },
    error: "",
    errorState: {
        isError: false,
        errorData: {
            status: 200,
            redirectLink: "",
            errorMessage: "An error occured, please try later",
        },
    },
};
const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={{ state, dispatch }}>
            {children}
        </Context.Provider>
    );
};

export const Context = createContext(initialState);

export default Store;
