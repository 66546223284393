import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { techiesparkConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import EventsHeader from "../../includes/events/EventsHeader";
import Footer from "../../includes/Footer";
import SectionLoader from "../../includes/loaders/SectionLoader";
import EventGallery from "./EventGallery";
import EventsSinglePage from "./EventsSinglePage";
import SimliarMoments from "./SimliarMoments";

export default function EventsLandingPage() {
    const [data, setData] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [eventDatas, setEventDatas] = useState([]);
    const { techiespark_id, event_id } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [isEventsLoading, setEventsLoading] = useState(true);
    const {
        state: {
            user_data: { access_token },
            events_data: { event_no },
        },
        dispatch,
    } = useContext(Context);

    useEffect(() => {
        techiesparkConfig
            .get(`techies-parks/event/${event_id}/`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then(function (response) {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setData(data);
                    // setGallery(data.gallery);
                    setLoading(false);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }, [event_id, event_no]);

    useEffect(() => {
        setEventsLoading(true);
        techiesparkConfig
            .get(`techies-parks/events/${techiespark_id}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then(function (response) {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setEventDatas(data);
                    setEventsLoading(false);
                } else if (StatusCode === 6001) {
                    setEventsLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setEventsLoading(false);
                dispatch({
                    type: "UPDATE_ERROR",
                    error: err,
                    errorMessage: "Server error please try again",
                });
            });
    }, [event_id, event_no]);

    return (
        <>
            {isLoading && isEventsLoading ? (
                <Cover>
                    <SectionLoader />
                </Cover>
            ) : (
                <>
                    <EventsHeader />
                    <EventsSinglePage data={data} event_no={event_no} />
                    <EventGallery />
                    {!isEventsLoading && (
                        <SimliarMoments eventDatas={eventDatas} />
                    )}
                    <Footer />
                </>
            )}
        </>
    );
}
const Cover = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;
