import React, { useContext, useEffect, useState } from "react";
import { techiesparkConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import styled from "styled-components";
import "../../../assets/css/style.css";
import { Link, useParams } from "react-router-dom";
import { getMonthDateYearStr } from "../../helpers/functions";
//components
import SectionLoader from "../../includes/loaders/SectionLoader";
import PlaceHolder from "../../general/PlaceHolder";

function Events() {
    const { techiespark_id } = useParams();
    const [eventdatas, setEventDatas] = useState([]);
    const [isLoading, setLoading] = useState(true);
    //image loading
    const [imageLoading, setImageLoading] = useState(true);
    function onLoad() {
        setTimeout(() => {
            setImageLoading(false);
        }, 100);
    }

    const {
        dispatch,
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    useEffect(() => {
        techiesparkConfig
            .get(`techies-parks/events/${techiespark_id}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then(function (response) {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setEventDatas(data);
                    setLoading(false);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                dispatch({
                    type: "UPDATE_ERROR",
                    error: err,
                    errorMessage: "Server error please try again",
                });
            });
    }, []);

    return (
        <div>
            {eventdatas.length > 0 && (
                <MainContainer id="events">
                    <div className="inner-wrapper">
                        <Heading>
                            <Small>
                                Events
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/under-line.svg"
                                    }
                                    alt="image"
                                />
                            </Small>
                        </Heading>
                        <Para>
                            Several events are being organised by Talrop and its
                            startups in Techies Parks to support
                            entrepreneurship and technological education.
                            Techies Parks is a venue that is open to both
                            governmental and non-governmental organisations.
                        </Para>

                        <div>
                            {isLoading ? (
                                <Cover>
                                    <SectionLoader />
                                </Cover>
                            ) : (
                                <>
                                    {eventdatas.map((event, index) => (
                                        <>
                                            {index <= 2 && (
                                                <DivConatiner
                                                    to={`/techies-park/${techiespark_id}/events/view/${event.id}/`}
                                                    key={event.id}
                                                    onClick={(e) => {
                                                        dispatch({
                                                            type: "UPDATE_EVENTS_DATA",
                                                            events_data: {
                                                                event_no:
                                                                    index + 1,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <LeftContainer>
                                                        <TopContent>
                                                            <Title>
                                                                {event.title}
                                                            </Title>
                                                            <Span>
                                                                {getMonthDateYearStr(
                                                                    event.event_date
                                                                )}
                                                            </Span>
                                                            <Paragraph
                                                                dangerouslySetInnerHTML={{
                                                                    __html: event?.description,
                                                                }}
                                                            />
                                                        </TopContent>
                                                        <BottomBox>
                                                            <Button>
                                                                View More
                                                                <img
                                                                    src={
                                                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/rightarrow.svg"
                                                                    }
                                                                    alt="image"
                                                                />
                                                            </Button>
                                                            <Divback>
                                                                {index == 0 ? (
                                                                    <Back
                                                                        src={
                                                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/01.png"
                                                                        }
                                                                        alt="image"
                                                                    />
                                                                ) : index ==
                                                                  1 ? (
                                                                    <Back
                                                                        src={
                                                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/02.png"
                                                                        }
                                                                        alt="image"
                                                                    />
                                                                ) : (
                                                                    <Back
                                                                        src={
                                                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/03.png"
                                                                        }
                                                                        alt="image"
                                                                    />
                                                                )}
                                                            </Divback>
                                                        </BottomBox>
                                                    </LeftContainer>
                                                    <RightContainer>
                                                        <PlaceHolder
                                                            isLoading={
                                                                imageLoading
                                                            }
                                                            // paddingTop="%"
                                                        />
                                                        <Image
                                                            style={{
                                                                display:
                                                                    imageLoading
                                                                        ? "none"
                                                                        : "block",
                                                            }}
                                                            src={
                                                                event.thumbnail_image
                                                            }
                                                            onLoad={onLoad}
                                                            alt="image"
                                                        />
                                                        {event.thumbnail_image && (
                                                            <Btn>
                                                                {event.program}
                                                            </Btn>
                                                        )}
                                                    </RightContainer>
                                                </DivConatiner>
                                            )}
                                        </>
                                    ))}
                                </>
                            )}
                        </div>

                        {eventdatas.length > 3 && (
                            <DivButton
                                to={`/techies-park/${techiespark_id}/events/`}
                            >
                                View more events
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/rightarrow.svg"
                                    }
                                    alt="image"
                                />
                            </DivButton>
                        )}
                    </div>
                </MainContainer>
            )}
        </div>
    );
}
const MainContainer = styled.div`
    padding: 120px 0;
    background: #fcfbf8;
    animation: 300ms ease-in-out 0ms 1 normal none running fade;
    @keyframes fade {
        0% {
            opacity: 0;
            transform: scale(0.9);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    @media all and (max-width: 980px) {
        padding: 80px 0;
    }
    @media all and (max-width: 640px) {
        padding: 70px 0;
    }
    @media all and (max-width: 480px) {
        padding: 60px 0;
    }
    @media all and (max-width: 360px) {
        padding: 50px 0;
    }
`;
const Heading = styled.h1`
    display: inline-block;
    font-size: 30px;
    font-family: "gordita_medium";
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    @media all and (max-width: 640px) {
        font-size: 25px;
    }
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
    @media all and (max-width: 360px) {
        font-size: 20px;
    }
`;
const Small = styled.span`
    position: relative;
    color: #0fa76f;
    display: inline-block;
    font-size: 30px;
    font-family: "gordita_medium";
    img {
        width: 99%;
        position: absolute;
        left: 7%;
        bottom: -2px;
    }
    @media all and (max-width: 640px) {
        font-size: 25px;
    }
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
    @media all and (max-width: 360px) {
        font-size: 20px;
    }
`;
const Span = styled.span`
    color: #adadad;
    font-size: 12px;
    display: flex;
    margin-bottom: 30px;
    @media all and (max-width: 980px) {
        margin-bottom: 20px;
    }
`;
const Para = styled.p`
    color: #747474;
    font-size: 16px;
    width: 75%;
    margin: 0 auto;
    font-family: "gordita_regular";
    text-align: center;
    margin-bottom: 50px;
    @media all and (max-width: 98px) {
        width: 80%;
    }
    @media all and (max-width: 768px) {
        width: 90%;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const DivConatiner = styled(Link)`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #fff6f9;
    border-radius: 10px;
    padding: 45px;
    margin-bottom: 20px;
    border: 1px solid #ffeaf1;
    animation: 300ms ease-in-out 0ms 1 normal none running fade;
    @keyframes fade {
        0% {
            opacity: 0;
            transform: scale(0.9);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
    &:nth-child(even) {
        flex-direction: row-reverse;
        background: #f1fcff;
        border: 1px solid #c8efef;
    }
    @media all and (max-width: 1280px) {
        padding: 30px;
    }
    @media all and (max-width: 980px) {
        padding: 20px;
    }
    @media all and (max-width: 850px) {
        padding: 30px;
        flex-direction: column-reverse;
        &:nth-child(even) {
            flex-direction: column-reverse;
        }
    }
    @media all and (max-width: 480px) {
        padding: 20px;
    }
    @media all and (max-width: 360px) {
        padding: 15px;
    }
`;
const LeftContainer = styled.div`
    position: relative;
    width: 54%;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
    @media all and (max-width: 980px) {
        grid-row-gap: 35px;
        width: 50%;
    }
    @media all and (max-width: 850px) {
        width: 100%;
    }
`;
const TopContent = styled.div``;
const Title = styled.span`
    font-size: 18px;
    font-family: "gordita_medium";
    display: inline-block;
    margin-bottom: 12px;
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
const Paragraph = styled.p`
    color: #747474;
    font-size: 16px !important;
    font-family: "gordita_regular";
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    @media all and (max-width:1400px){
        p {
            font-size: 14px !important;
        }
    }
    
`;
const Divback = styled.div`
    width: 120px;
    @media all and (max-width: 640px) {
        width: 110px;
    }
    @media all and (max-width: 480px) {
        display: none;
    }
`;
const Back = styled.img`
    display: block;
    width: 100%;
`;
const BottomBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* position: absolute;
    bottom: 0;
    width: 100%; */
`;
const Button = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 20px;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    cursor: pointer;
    img {
        width: 7px;
        margin-left: 15px;
    }
    background: #0fa76f;
    opacity: 0.9;
    &:hover {
        background-color: #0c7d54;
        transition: all 0.4s ease-in;
        opacity: 1;
    }
    @media all and (max-width: 640px) {
        padding: 12px 20px;
    }
    @media all and (max-width: 480px) {
        padding: 10px 15px;
        font-size: 13px;
    }
`;
const RightContainer = styled.div`
    width: 42%;
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    @media all and (max-width: 980px) {
        width: 45%;
    }
    @media all and (max-width: 850px) {
        width: 60%;
        margin: 0 auto 40px;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
    @media all and (max-width: 500px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Btn = styled.button`
    display: inline-block;
    padding: 8px 25px;
    background: rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(59.5px);
    opacity: 0.8;
    position: absolute;
    border-radius: 16px;
    bottom: 15px;
    left: 15px;
    color: #fff;
    font-family: "gordita_regular";
    font-size: 14px;
    @media all and (max-width: 360px) {
        padding: 6px 20px;
        bottom: 10px;
        left: 10px;
        font-size: 13px;
    }
`;

const Image = styled.img`
    display: block;
    width: 100%;
    object-fit: cover;
    height: 100%;
`;

const DivButton = styled(Link)`
    background: #0fa76f;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding: 15px 25px;
    margin: 60px auto 0;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-family: "gordita_medium";
    cursor: pointer;
    img {
        width: 7px;
        margin-left: 15px;
        @media all and (max-width: 480px) {
            display: none;
        }
    }
    @media all and (max-width: 640px) {
        padding: 14px 20px;
    }
    @media all and (max-width: 480px) {
        font-size: 16px;
        margin-top: 50px;
        color: #0fa76f;
        background: none;
        border-bottom: 1px solid #0fa76f;
        padding: 0;
        border-radius: 0;
    }
`;
const Cover = styled.div`
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default Events;
