const ChiefReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_USER_DATA":
            const user_data = { ...state.user_data, ...action.user_data };
            localStorage.setItem("user_data", JSON.stringify(user_data));
            return {
                ...state,
                user_data: user_data,
            };
        case "UPDATE_EVENTS_DATA":
            const events_data = { ...state.events_data, ...action.events_data };
            localStorage.setItem("events_data", JSON.stringify(events_data));
            return {
                ...state,
                events_data: events_data,
            };
        case "UPDATE_FILTER":
            return {
                ...state,
                isFilter: !state.isFilter,
            };
        case "UPDATE_SIDEBAR":
            return {
                ...state,
                isSideBar: action.isSideBar,
            };
        case "UPDATE_GALLERYDATA":
            return {
                ...state,
                galleryData: action.data,
            };
        case "UPDATE_SEARCH_PARAMS":
            return {
                ...state,
                currentParams: action.payload,
            };
        case "UPDATE_ERROR":
            //  default state of error status
            let isError = action.isError ? action.isError : true;
            let status = 400;
            // if the there is a error message from components then the error message is show else default error message is shown
            let errorMessage = action.errorMessage
                ? action.errorMessage
                : "An error occured, please try later";
            // used to update the redirect link in case of redirect link available
            let redirectLink = action.redirectLink ? action.redirectLink : "";

            // if there is a responce in error state
            if (action.error.response) {
                status = action.error.response.status;
            }
            // updates the store
            return {
                ...state,
                errorState: {
                    isError: true,
                    errorData: {
                        status: status,
                        errorMessage: errorMessage,
                        redirectLink: redirectLink,
                    },
                },
            };

        default:
            return state;
    }
};

export default ChiefReducer;
