import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

export default function EventModalHeader({
    gallery,
    selectedId,
    setSelectedId,
    setModal,
    setSelectedImage,
}) {
    const { techiespark_id } = useParams();

    return (
        <Container>
            <div className="wrapper">
                <Logo>
                    <LogoTag
                        to={`/techies-park/${techiespark_id}/`}
                        onClick={() => {
                            setModal(false);
                            selectedId("");
                        }}
                    >
                        <img
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/Techiespark_Logo.png"
                            }
                            alt="Logo"
                        />
                    </LogoTag>
                </Logo>
                <SubConatiner>
                <CloseContainer
                        onClick={() => {
                            setModal(false);
                            selectedId("");
                        }}
                    >
                        <CloseIcon
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/close_icon.svg"
                            }
                        />
                    </CloseContainer>
                    <SlideUl>
                    <SliderMenuBox>
                        {gallery.map((item) => {
                            return (
                                <SliderMenu
                                    onClick={() => {
                                        setSelectedId(item.id);
                                        setSelectedImage(item.image);
                                    }}
                                >
                                    <img
                                        src={item.image}
                                        className={
                                            item.id === selectedId
                                                ? "active"
                                                : ""
                                        }
                                    />
                                </SliderMenu>
                            );
                        })}
                    </SliderMenuBox>
                    </SlideUl>
                    {/* <CloseContainer
                        onClick={() => {
                            setModal(false);
                            selectedId("");
                        }}
                    >
                        <CloseIcon
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/close_icon.svg"
                            }
                        />
                    </CloseContainer> */}
                </SubConatiner>
            </div>
        </Container>
    );
}

const Container = styled.div`
    min-height: 100px;
    width: 100%;
    display: flex;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    border-bottom: 1px solid #d4d4d4;
    z-index: 1000;
    z-index: 1009;

    & .wrapper {
        width: 95%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media all and (max-width: 980px) {
            justify-content: center;
        }
    }
`;
const SubConatiner = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
`;
const Logo = styled.h1`
    width: 16%;
    margin-left: 20px;

    @media all and (max-width: 980px) {
        display: none;
    }
`;
const LogoTag = styled.div`
    font-size: 28px;
    img {
        display: block;
        width: 100%;
    }
`;

const SliderMenuBox = styled.div`
    display: flex;
    justify-content: flex-end;
    max-width: 600px;
    min-width: 600px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 768px) {
        max-width: 500px;
        min-width: 500px;
    }
    @media all and (max-width: 640px) {
        max-width: 400px;
        min-width: 400px;
    }
    @media all and (max-width: 480px) {
        max-width: 250px;
        min-width: 250px;
    }
`;
const SliderMenu = styled.div`
    max-width: 100px;
    min-width: 100px;
    margin-left: 10px;
    border-radius: 7px;
    /* overflow: hidden; */
    cursor: pointer;
    border: 1px solid #fff;

    /* &.active {
        border: 1px solid #0fa76f;
        box-shadow: 0px 0px 8px 2px rgba(15, 167, 111, 0.32);
        border-radius: 4px;
    } */
    & img {
        width: 100%;
        display: block;
        border: 1px solid #fff;
        &.active {
            border: 1px solid #0fa76f;
            /* box-shadow: 0px 0px 8px 2px rgba(15, 167, 111, 0.32); */
            border-radius: 4px;
        }
        :hover {
            border: 1px solid #0fa76f;
            /* box-shadow: 0px 0px 8px 2px rgba(15, 167, 111, 0.32); */
            border-radius: 4px;
        }
    }
`;
const CloseContainer = styled.div`
    margin-left: 10px;
    border-radius: 4px;
    background: #eeeeee;
    height: 36px;
    padding: 7px 7px;
    cursor: pointer;
`;
const CloseIcon = styled.img`
    display: block;
    width: 100%;
`;
const SlideUl = styled.div`
    overflow-x: scroll;
    width: 60%;


`