import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link, NavLink, Outlet } from "react-router-dom";
import $ from "jquery";

export default function Sidebar({ click, setClick }) {
    const [isHover, setisHover] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [texthover, setTextHover] = useState(false);

    function handleOnhover() {
        setisHover(true);
        setTimeout(() => {
            setTextHover(true);
        }, 150);
    }

    function handleOnleave() {
        setTextHover(false);
        setisHover(false);
    }

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setClicked(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    function handleOutsideClick() {
        setClick(false);
    }
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    useEffect(() => {
        if (click) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [click]);
    return (
        <>
            <Overlay
                onClick={() => handleOutsideClick()}
                className={click ? "active" : ""}
            ></Overlay>
            <MainContainer
                className={
                    click ? "active" : "" || isHover ? "active-hover" : ""
                }
                onMouseEnter={() => handleOnhover()}
                onMouseLeave={() => handleOnleave()}
            >
                <Textheading
                    className={click ? "active" : ""}
                    to="/"
                    onClick={() => setClick(false)}
                >
                    Techies Park
                </Textheading>
                <Divtop
                    className={
                        click ? "active" : "" || isHover ? "active-hover" : ""
                    }
                    to="/"
                    onClick={() => setClick(false)}
                >
                    {" "}
                    <ImageContainer
                        className={
                            click
                                ? "active"
                                : "" || isHover
                                ? "active-hover"
                                : ""
                        }
                    >
                        <Image
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/dashboardicon.svg"
                            }
                            alt="image"
                        />
                    </ImageContainer>
                    <Text
                        className={
                            click
                                ? "active"
                                : "" || texthover
                                ? "active-hover"
                                : ""
                        }
                    >
                        Dashboard
                    </Text>
                </Divtop>
            </MainContainer>
            <Outlet />
        </>
    );
}

const MainContainer = styled.div`
    width: 180px;
    position: fixed;
    height: calc(100vh - 80px);
    background-color: #fff;
    left: 0;
    bottom: 0;
    top: 80px;
    z-index: 999;
    transition: all 0.3s ease-in-out;
    @media all and (max-width: 1500px) {
        width: 65px;
    }
    &.active-hover {
        width: 180px;
        background: rgb(249, 249, 251);
        transition: all 0.3s ease-in-out;
        & span {
            opacity: 1;
        }
    }

    @media all and (max-width: 480px) {
        display: none;
        z-index: 999;
        width: 180px;
        background: rgb(249, 249, 251);
        transition: all 0.2s ease-in-out;
        &.active {
            display: block;
            transition: all 0.2s ease-in-out;
            padding-top: 0px;
        }
    }
`;
const Divtop = styled(NavLink)`
    display: flex;
    margin-bottom: 20px;
    padding: 10px 10px 10px 20px;
    background: #f4f5fc;
    align-items: center;
    @media all and (max-width: 768px) {
        flex-wrap: wrap;
    }
    @media all and (max-width: 480px) {
        background: #e8e8f3;
    }
`;
const ImageContainer = styled.div`
    width: 20px;
`;
const Image = styled.img`
    width: 100%;
    display: block;
`;
const Text = styled.span`
    color: #0f0f0f;
    font-size: 14px;
    margin-left: 18px;
    font-family: "gordita_medium";
    position: absolute;
    display: block;
    left: 20%;
    @media all and (max-width: 1500px) {
        display: none;
    }
    &.active-hover {
        display: block;
    }
    @media all and (max-width: 480px) {
        display: block;
    }
`;
const Divbottom = styled(NavLink)`
    display: flex;
    padding: 10px 10px 10px 20px;
    @media all and (max-width: 768px) {
        flex-wrap: wrap;
    }
`;
const Textheading = styled(Link)`
    font-size: 18px;
    font-family: gordita_bold;
    color: #247d3d;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    margin: 10px;
    display: none;
    &.active {
        background: rgb(249, 249, 251);
        transition: all 0.5s ease-in-out;
        display: block;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;
const Overlay = styled.div`
    transition: all 0.5s ease;
    &.active {
        background: #00000075;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        right: 0;
        transition: all 0.5s ease;
    }
`;
