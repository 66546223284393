import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import SectionLoader from "../../../helpers/SectionLoader";
// components
import Login from "../../../screens/authentication/Login";

const AuthRouter = () => {
    return (
        <Suspense
            fallback={
                <Cover>
                    <SectionLoader />
                </Cover>
            }
        >
            <Routes>
                <Route path="login" element={<Login />} />
            </Routes>
        </Suspense>
    );
};

export default AuthRouter;
const Cover = styled.div`
    height: 100vh;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`;
