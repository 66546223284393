import React from "react";
import styled from "styled-components";
import Gif from "../../assets/animations/techies-park.gif";

export default function PlaceHolder({ paddingTop, isLoading, color, width }) {
    return (
        <Container
            style={{
                display: isLoading ? "block" : "none",
                paddingTop: isLoading ? paddingTop : "0",
            }}
        >
            <InnerContainer
                style={{
                    display: isLoading ? "flex" : "none",
                    display: color ? "color" : "#f3f3f3",
                }}
            >
                <LogoImage
                    style={{
                        width: width ? width : "60%",
                    }}
                    alt="Logo"
                    src={Gif}
                />
            </InnerContainer>
        </Container>
    );
}

const Container = styled.div`
    position: relative;
`;
const InnerContainer = styled.div`
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    background: #f3f3f3;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LogoImage = styled.img`
    display: block;
    width: 60%;
`;
