import React from "react";
import styled from "styled-components";
import "../../../assets/css/style.css";

function Models() {
    const revenueModelDetails = [
        {
            id: 1,
            defaulticon:
                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/stratup-green.svg",
            hovericon:
                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/course-fee.svg",
            title: "Course Fee",
            description:
                "When students are admitted to Steyp through Techies Park, for each admission, Techies Park will receive a fixed amount of commission.",
        },
        {
            id: 2,
            defaulticon:
                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/startup.svg",
            hovericon:
                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/white.svg",
            title: "Startup Rent",
            description:
                "Techies park provides the perfect space for startups to grow and evolve. Techies Park is provided with an amount of rent by the start ups.",
        },
        {
            id: 3,
            defaulticon:
                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/workspace.svg",
            hovericon:
                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/workspace-white-new.svg",
            title: "Co-Working Space",
            description:
                "Techies Park will receive rent from the startups for the co-working environment provided to them.",
        },
        {
            id: 4,
            defaulticon:
                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/revenue.svg",
            hovericon:
                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/revenue-white-new.svg",
            title: "Startup Revenue",
            description:
                "Techies Park initiate the development of startups within them and  helps start ups to find lead which helps in their fast journey. Revenue is generated by the programmes initiated by Techies Park.",
        },
    ];
    return (
        <MainContainer id="revenue">
            <div className="wrapper">
                <Heading>
                    Revenue
                    <Small>
                        Models
                        <img
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/under-line.svg"
                            }
                            alt="image"
                        />
                    </Small>
                </Heading>
                <Sentence>
                    <Para>
                        To build a strong startup ecosystem and accelerate
                        startups, Talrop is establishing Techies Parks in all
                        140 Assembly Constituencies of Kerala. 7 of them are
                        already established and remaining 133 will be done by
                        the end of 2024.
                    </Para>
                </Sentence>
                <DivBox>
                    {revenueModelDetails.map((item) => (
                        <DivContain key={item.id}>
                            <ImageConatiner className="green">
                                <Image
                                    src={item.defaulticon}
                                    alt="default-icon"
                                />
                            </ImageConatiner>
                            <ImageContain className="white">
                                <Contain
                                    src={item.hovericon}
                                    alt="hover-icon"
                                />
                            </ImageContain>
                            <Text>{item.title}</Text>
                            <Paragraph>{item.description}</Paragraph>
                        </DivContain>
                    ))}
                </DivBox>
            </div>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    padding: 120px 0;
    @media all and (max-width: 980px) {
        padding: 80px 0;
    }
    @media all and (max-width: 640px) {
        padding: 70px 0;
    }
    @media all and (max-width: 480px) {
        padding: 60px 0;
    }
    @media all and (max-width: 360px) {
        padding: 50px 0;
    }
`;
const DivBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 30px;
    @media all and (max-width: 1280px) {
        grid-column-gap: 20px;
    }
    @media all and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
    }
    @media all and (max-width: 1080px) {
        grid-gap: 25px;
    }
    @media all and (max-width: 980px) {
        grid-gap: 20px;
    }
    @media all and (max-width: 850px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 25px;
    }
    @media all and (max-width: 570px) {
        grid-gap: 20px;
    }
    @media all and (max-width: 480px) {
        grid-template-columns: 1fr;
        grid-gap: 25px;
    }
`;
const Heading = styled.h1`
    display: inline-block;
    font-size: 30px;
    font-family: "gordita_medium";
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    @media all and (max-width: 640px) {
        font-size: 25px;
    }
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
    @media all and (max-width: 360px) {
        font-size: 20px;
    }
`;
const Small = styled.span`
    position: relative;
    color: #0fa76f;
    display: inline-block;
    margin-left: 5px;
    font-size: 30px;
    font-family: "gordita_medium";
    img {
        width: 84%;
        position: absolute;
        left: 25%;
        bottom: -2px;
    }
    @media all and (max-width: 640px) {
        font-size: 25px;
    }
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
    @media all and (max-width: 360px) {
        font-size: 20px;
    }
`;
const Span = styled.span`
    color: #747474;
    font-size: 14px;
    display: flex;
    margin-top: 9px;
`;
const Sentence = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
`;
const Para = styled.p`
    color: #747474;
    font-size: 16px;
    width: 70%;
    text-align: center;
    @media all and (max-width: 768px) {
        font-size: 15px;
        width: 80%;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
        width: 100%;
    }
`;
const DivContain = styled.div`
    border: 1px solid #eeeeee;
    border-radius: 5px;
    padding: 25px;
    transform: scale(0.9);
    transition: all .2s ease-in-out;
    &:hover {
        background: linear-gradient(90deg, #0fa76f 2%, #21c487 97.59%);
        transform: scale(1);
     
    }
    
    
    &:hover span {
        color: #fff;
    }
    &:hover p {
        color: #fff;
    }
    &:hover .green {
        display: none;
    }

    &:hover .white {
        display: block;
    }
    @media all and (max-width: 1280px) {
        padding: 15px;
    }
    @media all and (max-width: 1200px) {
        padding: 25px;
    }
    @media all and (max-width: 980px) {
        padding: 20px;
    }
    @media all and (max-width: 850px) {
        padding: 25px;
    }
    @media all and (max-width: 570px) {
        padding: 15px;
    }
    @media all and (max-width: 480px) {
        padding: 25px;
    }
`;
const ImageConatiner = styled.div`
    width: 25px;
    margin-bottom: 10px;
    &:hover {
        display: none;
    }
`;
const Image = styled.img`
    width: 100%;
    display: block;
`;
const ImageContain = styled.div`
    display: none;
    width: 25px;
    margin-bottom: 10px;
    &:hover {
        display: block;
    }
`;
const Contain = styled.img`
    width: 100%;
    display: block;
`;

const Text = styled.span`
    display: inline-block;
    font-family: "gordita_medium";
    margin-bottom: 20px;
    font-size: 16px;
    /* transition: all 0.3s ease-in; */
    @media all and (max-width: 980px) {
        margin-bottom: 15px;
    }
`;
const Paragraph = styled.p`
    font-size: 14px;
    /* transition: all 0.3s ease-in; */
    @media all and (max-width: 1080px) {
        font-size: 13px;
    }
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;

export default Models;
