import React from "react";
import styled from "styled-components";

function DataNotFound() {
    return (
        <MainContainer>
            <ImgContainer>
                <IMG
                    src={
                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/not-found.svg"
                    }
                />
            </ImgContainer>
            <Heading>No Result Found</Heading>
            <Description>
                It seems we can’t find any results based on your search.
            </Description>
        </MainContainer>
    );
}

export default DataNotFound;

const MainContainer = styled.div`
    margin: 0 auto;
    margin-top: 150px;
`;
const ImgContainer = styled.div`
    width: 90px;
    margin: 0 auto;
    margin-bottom: 25px;
`;
const IMG = styled.img`
    display: block;
    width: 100%;
`;
const Heading = styled.h1`
    text-align: center;
    font-size: 22px;
    font-family: "gordita_medium";
    margin-bottom: 15px;
`;

const Description = styled.p`
    text-align: center;
`;
