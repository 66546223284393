import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Context } from "../../contexts/Store";
//axios
import { techiesparkConfig } from "../../../axiosConfig";
//components
import Footer from "../../includes/Footer";
import EventCard from "../../includes/events/EventCard";
import LandingPageHeader from "../../includes/LandingPageHeader";
import SectionLoader from "../../includes/loaders/SectionLoader";
import DataNotFound from "../DataNotFound";
import {useDebounce} from "../../helpers/hooks"

import rightarrow from "../../../assets/images/right.svg";
import leftarrow from "../../../assets/images/left.svg";


function EventsListPage() {
    let eventsList = "eventsList";
    const { techiespark_id } = useParams();
    const [eventDatas, setEventDatas] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [isPageLoading, setPageLoading] = useState(false);
    const {
        state: {
            user_data: { access_token },
        },
        dispatch,
    } = useContext(Context);
    //searching
    const [searchWord, setSearchWord] = useState("");
    const debouncedValue = useDebounce(searchWord);
    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage + 1);
        setSearchParams({
            ...currentParams,
            page: selectedPage + 1,
        });
    };

    const getStudentStatusParams = () => {
        let eventParams = {};
        searchParams.get("page") &&
            (eventParams.page = searchParams.get("page"));
        const search = searchParams.get("q");
        eventParams.search = search;
        return eventParams;
    };

    //events list api
    useEffect(() => {
        const params = getStudentStatusParams();
        setPageLoading(true);
        techiesparkConfig
            .get(`techies-parks/events/${techiespark_id}/?q=${searchWord}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
                params: params,
            })
            .then(function (response) {
                const { StatusCode, data, pagination_data } = response.data;
                if (StatusCode === 6000) {
                    setLoading(false);
                    setPageLoading(false);
                    setEventDatas(data);
                    setPagination(pagination_data);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                    setPageLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                setPageLoading(false);
                dispatch({
                    type: "UPDATE_ERROR",
                    error: err,
                    errorMessage: "Server error please try again",
                });
            });
    }, [currentPage, searchParams, debouncedValue]);

    //scroll
    useEffect(() => {
        var element = document.getElementById("events");
        element?.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    }, []);

    return (
        <>
            <LandingPageHeader eventsList={eventsList} />
            <MainContainer id="events">
                <div className="wrapper">
                    <SubContainer>
                        <TopContainer>
                            <EventHeading>
                                <SpanBox>Events</SpanBox>
                            </EventHeading>
                            <ContentContainer>
                                Several events are being organised by Talrop and
                                its startups in Techies Parks to support
                                entrepreneurship and technological education.
                                Techies Parks is a venue that is open to both
                                governmental and non-governmental organizations.
                            </ContentContainer>
                            <SearchContainer>
                                <SearchBar>
                                    <SearchImgContainer>
                                        <SearchImg
                                            src={
                                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/Search-icon.svg"
                                            }
                                            alt="SearchImage"
                                        />
                                    </SearchImgContainer>
                                    <Input
                                        type="text"
                                        class="search-box"
                                        placeholder="Search Events Here"
                                        onChange={(e) => {
                                            setSearchWord(e.target.value);
                                        }}
                                        value={searchWord}
                                    />
                                    {searchWord && (
                                        <>
                                            <CloseIcon
                                                onClick={() => {
                                                    setSearchWord("");
                                                }}
                                                to={`/techies-park/${techiespark_id}/events/`}
                                            >
                                                <img
                                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                                    alt="Icon"
                                                />
                                            </CloseIcon>
                                        </>
                                    )}
                                </SearchBar>
                            </SearchContainer>
                        </TopContainer>
                        {isLoading ? (
                            <Cover>
                                <SectionLoader />
                            </Cover>
                        ) : isPageLoading ? (
                            <Cover>
                                <SectionLoader />
                            </Cover>
                        ) : (
                            <>
                                {eventDatas.length != 0 ? (
                                    <MainList>
                                        {eventDatas &&
                                            eventDatas?.map((item, index) => (
                                                <EventCard
                                                    item={item}
                                                    index={index}
                                                />
                                            ))}
                                    </MainList>
                                ) : (
                                    <DataNotFound />
                                )}
                            </>
                        )}
                        {pagination?.total_pages > 1 &&
                            eventDatas?.length > 0 && (
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    pageCount={pagination?.total_pages}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination"}
                                    previousLinkClassName={"pagination__link"}
                                    nextLinkClassName={"pagination__link"}
                                    disabledClassName={
                                        "pagination__link--disabled"
                                    }
                                    activeClassName={"pagination__link--active"}
                                />
                            )}
                    </SubContainer>
                </div>
            </MainContainer>
            <Footer />
        </>
    );
}

export default EventsListPage;

const MainContainer = styled.div`
    padding-top: calc(5% + 80px);
    padding-bottom: 50px;
    animation: 300ms ease-in-out 0ms 1 normal none running fade;
    @keyframes fade {
        0% {
            opacity: 0;
            transform: scale(0.9);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
`;
const Cover = styled.div`
    height: 70vh;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const MainList = styled.ul`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    @media all and (max-width: 1200px) {
        grid-gap: 30px;
    }
    @media all and (max-width: 1080px) {
        grid-template-columns: 1fr 1fr;
    }
    @media all and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;
const SubContainer = styled.div``;
const TopContainer = styled.div``;
const EventHeading = styled.h1`
    text-align: center;
    font-family: gordita_medium;
    position: relative;
    margin-bottom: 20px;
    @media all and (max-width: 980px) {
        font-size: 28px;
    }
    @media all and (max-width: 980px) {
        font-size: 26px;
    }
    @media all and (max-width: 360px) {
        font-size: 24px;
    }
`;
const SpanBox = styled.span`
    color: #62af84;
    font-family: gordita_medium;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        bottom: -5px;
        right: 0;
        width: 75px;
        height: 10px;
        background-image: url("https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/green-layer.png");
        background-repeat: no-repeat;
        background-size: contain;
        @media all and (max-width: 980px) {
            width: 65px;
        }
        @media all and (max-width: 980px) {
            bottom: -2px;
            right: -6px;
        }
    }
`;
const ContentContainer = styled.p`
    width: 60%;
    margin: 0 auto 40px;
    text-align: center;
    font-size: 16px;
    @media all and (max-width: 1280px) {
        width: 70%;
    }
    @media all and (max-width: 980px) {
        width: 90%;
    }
    @media all and (max-width: 768px) {
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const SearchBar = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #d4d4d4;
    width: 35%;
    padding: 14px 13px;
    border-radius: 8px;
    @media all and (max-width: 980px) {
        padding: 10px 12px;
    }
    @media all and (max-width: 768px) {
        width: max-content;
    }
    @media all and (max-width: 480px) {
        /* width: 55%; */
    }
    @media all and (max-width: 360px) {
        padding: 8px 12px;
    }
`;
const CloseIcon = styled(Link)`
    position: absolute;
    right: 14px;
    bottom: 16px;
    width: 20px;
    cursor: pointer;
    animation: 300ms ease-in-out 0ms 1 normal none running fade;
    @keyframes fade {
        0% {
            opacity: 0;
            transform: scale(0.9);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    @media all and (max-width: 980px) {
        right: 14px;
        bottom: 14px;
        width: 15px;
    }
    @media all and (max-width: 360px) {
        right: 14px;
        bottom: 10px;
        width: 15px;
    }

    img {
        width: 100%;
        display: block;
    }
`;
const SearchButton = styled(Link)`
    width: 110px;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    background: rgb(15, 167, 111);
    padding: 14px 13px;
    cursor: pointer;
    animation: 300ms ease-in-out 0ms 1 normal none running fade;
    @keyframes fade {
        0% {
            opacity: 0;
            transform: scale(0.9);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    @media all and (max-width: 980px) {
        padding: 10px 12px;
    }
    @media all and (max-width: 480px) {
        width: 90px;
    }
    @media all and (max-width: 360px) {
        padding: 8px 12px;
    }
`;
const SearchImgContainer = styled.div`
    width: 18px;
    margin-right: 10px;
    @media all and (max-width: 640px) {
        width: 14px;
    }
`;
const SearchImg = styled.img`
    width: 100%;
    display: block;
`;
const Input = styled.input`
    font-size: 14px !important;
    color: #000 !important;
    width: 100%;

    &::placeholder {
        color: #adadad;
        opacity: 0.5;
    }
    @media all and (max-width: 480px) {
        width: 170px;
    }
    @media all and (max-width: 360px) {
        width: 140px;
    }
`;
const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 50px;
`;
