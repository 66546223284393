import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import "../../../assets/css/style.css";
import VideoPlayer from "../../applications/video-player/src/VideoPlayer";
import $ from "jquery";

function VideoModal({ isModal, setModal, videoUrl, modalType }) {
    const videoJsOptions = {
        autoplay: true,
        controls: false,
    };
    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);
    return (
        isModal && (
            <>
                {isModal ? (
                    <Overlay onClick={() => setModal(false)}></Overlay>
                ) : (
                    ""
                )}
                <BackContainer
                    id="invite-video"
                    style={{ transform: isModal && "scale(1,1)" }}
                >
                    <VideoModalContainer>
                        <Video className="player-wrapper">
                            <VideoPlayer
                                {...videoJsOptions}
                                source={videoUrl}
                            />
                        </Video>

                        <CloseIconContainer onClick={() => setModal(false)}>
                            <CloseIcon
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/cross.svg"
                                }
                                alt="cross icon"
                            />
                        </CloseIconContainer>
                    </VideoModalContainer>
                </BackContainer>
            </>
        )
    );
}
export default VideoModal;
const videoAnimation = keyframes`
 0% { transform:scale(0,0); opacity:0; }
 100% { transform:scale(1,1); opacity:1; }
`;
const BackContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    transition: 0.3s;
    width: 100%;
    transform: scale(0, 0);
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0;
`;
const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    animation-name: ${videoAnimation};
    animation-duration: 0.3s;
`;

const VideoModalContainer = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 9px;
    background-color: #000;
    width: 70%;

    @media (max-width: 480px) {
        width: 97%;
    }
`;
const Video = styled.div`
    position: relative;
`;
const CloseIcon = styled.img`
    display: block;
    width: 100%;
`;
const CloseIconContainer = styled.div`
    position: absolute;
    top: 0;
    right: -35px;
    width: 25px;
    cursor: pointer;
    @media (max-width: 640px) {
        right: -30px;
        width: 22px;
    }
    @media (max-width: 480px) {
        right: 0;
        top: -28px;
        width: 20px;
    }
`;
