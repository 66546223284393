import axios from "axios";

// ### (LOCAL IP CONFIGS) ### //
// export const techiesparkConfig = axios.create({
//     baseURL: "http://192.168.1.12:8000/api/v1/",
// });

//#### DEVELOPER SERVER CONFIGS #### //
// export const techiesparkConfig = axios.create({
//     baseURL: "https://developers-api.techiespark.in/api/v1/",
// });

// #### LIVE SERVER CONFIGS #### //
export const techiesparkConfig = axios.create({
    baseURL: "https://api.techiespark.in/api/v1/",
});
