import React, { useState, useEffect } from "react";
import { useContext } from "react";
import styled from "styled-components";
//axios
import { techiesparkConfig } from "../../axiosConfig";
//store
import { Context } from "../contexts/Store";
import Sidebar from "../includes/Sidebar";
// components
import Topbar from "../includes/Topbar";
import Dashboard from "../screens/Dashboard";
import {useDebounce} from "../helpers/hooks"

function TechiesparkDashboard() {
    const [sidebar, setSidebar] = useState(false);
    const [techiesparkData, setTechiesparkData] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const {
        state: {
            user_data: { access_token },
        },
        dispatch,
    } = useContext(Context);

    //searching
    const [searchWord, setSearchWord] = useState("");
    const debouncedValue = useDebounce(searchWord);
    //techiespark list api
    useEffect(() => {
        techiesparkConfig
            .get(`techies-parks/?q=${searchWord}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then(function (response) {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setTechiesparkData(data);
                    setLoading(false);
                } else if (StatusCode === 6001) {
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                dispatch({
                    type: "UPDATE_ERROR",
                    error: err,
                    errorMessage: "Server error please try again",
                });
            });
        console.log(debouncedValue, "=========debouncedValue============")
    }, [debouncedValue]);

    return (
        <Container>
            <Topbar
                sidebar={sidebar}
                setSidebar={setSidebar}
                searchWord={searchWord}
                setSearchWord={setSearchWord}
            />
            <Sidebar click={sidebar} setClick={setSidebar} />
            <Dashboard
                click={sidebar}
                setClick={setSidebar}
                isLoading={isLoading}
                techiesparkData={techiesparkData}
            />
        </Container>
    );
}

export default TechiesparkDashboard;
const Container = styled.div`
    background-color: #f4f5fc;
    height: 100vh;
`;
