import React from "react";
import styled from "styled-components";
import "../../assets/css/style.css";

function Footer() {
    return (
        <div>
            <MainContainer>
                <Content className="wrapper">
                    <LeftContainer>
                        <ImageDiv href="/techies-parks/">
                            <Div
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/techies.png"
                                }
                                alt="image"
                            />
                        </ImageDiv>
                        <Span>A Project from</Span>
                        <ImageContainer
                            href="https://talrop.com/"
                            target="_blank"
                        >
                            <Image
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/Techieswhite.svg"
                                }
                                alt="image"
                            />
                        </ImageContainer>
                    </LeftContainer>
                </Content>
                <Bottom>
                    <DivRight>
                        <ImageTop>
                            <ImageBox
                                href="https://www.instagram.com/talropworld/"
                                target="_blank"
                            >
                                <Top
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/instagram.svg"
                                    }
                                    alt="instagram"
                                />
                            </ImageBox>
                            <ImageBox
                                className="facebook"
                                href="https://www.facebook.com/talropworld/"
                                target="_blank"
                            >
                                <Top
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/facebook.svg"
                                    }
                                    alt="facebook"
                                />
                            </ImageBox>
                            <ImageBox
                                href="https://twitter.com/talropworld/"
                                target="_blank"
                                type="image"
                            >
                                <Top
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/twitter.svg"
                                    }
                                    alt="twitter"
                                />
                            </ImageBox>
                            <ImageBox
                                href="https://www.linkedin.com/company/talrop/"
                                target="_blank"
                            >
                                <Top
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/linkedin.svg"
                                    }
                                    alt="linkedin"
                                />
                            </ImageBox>
                            <ImageBox
                                href="https://www.youtube.com/c/talrop"
                                target="_blank"
                                type="youtube"
                            >
                                <Top
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/youtube.svg"
                                    }
                                    alt="image"
                                />
                            </ImageBox>
                        </ImageTop>
                    </DivRight>
                </Bottom>
            </MainContainer>
        </div>
    );
}

const MainContainer = styled.div`
    background: #1e1e1e;
    padding-top: 40px;
`;
const Content = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 30px;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   width: 100%;
    //   height: 1px;
    //   top: 150px;
    //   background: #303030;

    //   @media all and (max-width: 768px) {
    //     top: 280px;
    //   }
    //   @media all and (max-width: 640px) {
    //     top: 322px;
    //   }
    //   @media all and (max-width: 480px) {
    //     top: 348px;
    //   }
    // }
    // @media all and (max-width: 768px) {
    //   flex-wrap: wrap;
    //   margin: 0 auto;
    // }
`;
const LeftContainer = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   width: 100%;
    //   height: 1px;
    //   // top: 150px;

    //   background: #303030;
    // }
    @media all and (max-width: 1080px) {
        width: 35%;
    }
    @media all and (max-width: 980px) {
        width: 45%;
    }
    @media all and (max-width: 768px) {
        width: 55%;
    }
    @media all and (max-width: 640px) {
        // margin-bottom: 20px;
        width: 65%;
    }
    // @media all and (max-width: 640px) {
    //   margin-bottom: 40px;
    // }
    @media all and (max-width: 480px) {
        // margin-bottom: 20px;
        width: unset;
    }
`;
const ImageContainer = styled.a`
    width: 17%;
    cursor: pointer;
    @media all and (max-width: 768px) {
        width: 15%;
    }
`;
const Image = styled.img`
    width: 100%;
    display: block;
`;
const ImageDiv = styled.a`
    width: 60%;
    margin-bottom: 25px;
`;
const Div = styled.img`
    width: 100%;
    display: block;
`;
const RightContainer = styled.div`
    width: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* @media all and (max-width:768px){
  width: 90%;
 } */
    @media all and (max-width: 768px) {
        width: 100%;
        justify-content: space-between;
    }
    @media all and (max-width: 480px) {
        flex-wrap: wrap;
    }
`;
const Contact = styled.div`
    width: 40%;
    @media all and (max-width: 980px) {
        margin-right: 30px;
    }
    @media all and (max-width: 768px) {
        width: 50%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
        margin-bottom: 25px;
        margin-right: 0px;
    }
`;
const Text = styled.span`
    font-size: 16px;
    color: #ebebeb;
    display: flex;
    margin-bottom: 10px;
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        display: flex;
        justify-content: center;
    }
`;
const Phnno = styled.a`
    color: #ebebebdb;
    display: inline-block;
    font-family: "gordita_regular";
    font-size: 14px;
    margin-top: 10px;
    @media all and (max-width: 980px) {
        font-size: 12px;
    }
    @media all and (max-width: 480px) {
        display: flex;
        justify-content: center;
    }
`;
const Email = styled.a`
    color: #ebebebdb;
    font-size: 14px;
    display: flex;
    margin-top: 10px;
    font-family: "gordita_regular";
    @media all and (max-width: 980px) {
        font-size: 12px;
    }
    @media all and (max-width: 480px) {
        display: flex;
        justify-content: center;
    }
`;
const Address = styled.div`
    width: 52%;
    margin-top: 10px;
    @media all and (max-width: 768px) {
        width: 50%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
        margin: 0 auto;
    }
`;
const Small = styled.span`
    color: #ebebeb;
    font-family: "gordita_regular";
    display: inline-block;
    font-size: 16px;
    margin-bottom: 10px;
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        display: flex;
        justify-content: center;
    }
`;
const Span = styled.span`
    color: #d6d6d6;
    font-family: "gordita_medium";
    display: inline-block;
    display: flex;
    font-size: 16px;
    text-align: center;
    margin-bottom: 25px;
`;
const Bottom = styled.div`
    display: flex;
    justify-content: center;
    // margin-top: 50px;
    padding: 30px 0px 40px;
    border-top: 1px solid #303030;

    align-items: flex-end;
    @media all and (max-width: 640px) {
        flex-wrap: wrap;
    }
`;
const DivLeft = styled.div`
    width: 35%;
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const Title = styled.span`
    font-size: 14px;
    color: #ebebebdb;
    font-family: "gordita_regular";
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    @media all and (max-width: 980px) {
        font-size: 12px;
    }
    @media all and (max-width: 640px) {
        font-size: 14px;
        margin-bottom: 30px;
    }
`;
const DivRight = styled.div`
    width: 35%;
    @media all and (max-width: 640px) {
        width: 100%;
        margin: 0 auto;
    }
`;
const ImageBox = styled.a`
    width: 30px;
    margin-right: 10px;
    display: flex;
    justify-content: center;

    &:last-child {
        margin-right: 0px;
    }
    @media all and (max-width: 640px) {
        width: 22px;
    }
`;
const Top = styled.img`
    width: fit-content;
    display: block;
`;
const ImageTop = styled.div`
    width: 55%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @media all and (max-width: 768px) {
        width: 65%;
    }
    @media all and (max-width: 640px) {
        margin: 0 auto;
    }

    /* @media all and (max-width:640px){
	width:50%;
 }
 @media all and (max-width:360px){
	width:60%;
 } */
`;

export default Footer;
