import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link, Outlet } from "react-router-dom";
import { Context } from "../contexts/Store";

export default function Topbar({
    sidebar,
    setSidebar,
    searchWord,
    setSearchWord,
}) {
    const [clicked, setClicked] = useState(false);
    const {
        dispatch,
        state: {
            user_data: { name },
        },
    } = useContext(Context);
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setClicked(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    return (
        <div>
            <MainContainer>
                <DivContain>
                    <Textheading to="/">Techies Park</Textheading>
                    <HumbergContainer onClick={() => setSidebar(!sidebar)}>
                        <Hamberg
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/humbergimage.svg"
                            }
                            alt="image"
                        />
                    </HumbergContainer>
                    <DivText>
                        <HumbergContainer>
                            <Hamberg
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/humbergimage.svg"
                                }
                                alt="image"
                            />
                        </HumbergContainer>
                        <InputDiv>
                            <img
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/Search.svg"
                                }
                                alt="image"
                            />
                            <InputField
                                className="search "
                                type="text"
                                placeholder="Search Here"
                                onChange={(e) => {
                                    setSearchWord(e.target.value);
                                }}
                                value={searchWord}
                            />
                            {searchWord && (
                                <CloseIcon
                                    to=""
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSearchWord("");
                                    }}
                                >
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                        alt="Icon"
                                    />
                                </CloseIcon>
                            )}
                        </InputDiv>
                    </DivText>
                </DivContain>
                <DivTotal ref={wrapperRef} onClick={() => setClicked(!clicked)}>
                    <ProfileDiv>
                        <ProfileImage>
                            <NameSpan>{name?.charAt(0).toUpperCase()}</NameSpan>
                        </ProfileImage>
                        <Name
                            onClick={() => setClicked(!clicked)}
                            ref={wrapperRef}
                        >
                            {name}
                        </Name>
                        <Downarrow
                            onClick={() => setClicked(!clicked)}
                            ref={wrapperRef}
                        >
                            <Arrow
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/downicon.svg"
                                }
                                alt="image"
                            />
                        </Downarrow>
                    </ProfileDiv>
                    <DivLogout
                        className={clicked ? "active" : ""}
                        onClick={() => {
                            dispatch({
                                type: "UPDATE_USER_DATA",
                                user_data: {
                                    is_verified: false,
                                    access_token: "",
                                },
                            });
                        }}
                    >
                        <DivDrop ref={wrapperRef}>
                            <DivBottom>
                                <Image>
                                    <ImgDrop
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/logouticon.svg"
                                        }
                                        alt="icon"
                                    />
                                </Image>
                                <Icon>Log Out</Icon>
                            </DivBottom>
                        </DivDrop>
                    </DivLogout>
                </DivTotal>
            </MainContainer>

            <Outlet />
        </div>
    );
}

const MainContainer = styled.div`
    height: 80px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    background-color: #fff;
    z-index: 999;
    @media all and (max-width: 480px) {
        padding: 20px 20px 20px 10px;
    }
`;
const CloseIcon = styled(Link)`
    position: absolute;
    right: 12px;
    bottom: 7px;
    width: 22px;
    background: #9b9a9a5c;
    padding: 5px;
    border-radius: 25px;
    cursor: pointer;
    animation: 300ms ease-in-out 0ms 1 normal none running fade;
    @keyframes fade {
        0% {
            opacity: 0;
            transform: scale(0.9);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    img {
        width: 100% !important;
        display: block;
    }
`;
const DivContain = styled.div`
    display: flex;
    width: 45%;
    align-items: center;
    @media all and (max-width: 1080px) {
        width: 50%;
    }
    @media all and (max-width: 980px) {
        width: 70%;
    }
    @media all and (max-width: 640px) {
        width: 60%;
    }
    @media all and (max-width: 460px) {
        width: 45%;
        justify-content: flex-start;
    }
`;
const Textheading = styled(Link)`
    font-size: 18px;
    font-family: gordita_bold;
    color: #247d3d;
    cursor: pointer;
    text-transform: uppercase;
    @media all and (max-width: 480px) {
        font-size: 16px;
        display: none;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;
const DivText = styled.div`
    display: flex;
    width: 65%;
    align-items: center;
    @media all and (max-width: 768px) {
        display: none;
    }
`;
const HumbergContainer = styled.div`
    width: 56px;
    display: none;
    @media all and (max-width: 480px) {
        display: block;
    }
`;
const Hamberg = styled.img`
    width: 100%;
    display: block;
`;
const InputDiv = styled.div`
    position: relative;
    border: 1px solid #eeeeee;
    width: 100%;
    height: 36px;
    padding: 4px 8px;
    color: #5a5a5a;
    font-size: 12px;
    margin-left: 34px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    img {
        width: 15px;
        cursor: pointer;
    }
`;
const InputField = styled.input`
    font-size: 12px !important;
    margin-left: 10px !important;
    transform: translateY(1px);
    color: #000 !important;
    width: 100%;
`;
const DivTotal = styled.div``;
const ProfileDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
const ProfileImage = styled.div`
    width: 40px;
    height: 40px;

    border-radius: 50%;
    font-size: 18px;
    border: 1px solid rgb(15, 167, 111);

    min-width: 40px;
    position: relative;
`;
const NameSpan = styled.span`
    color: rgb(15, 167, 111);
    position: absolute;
    top: 52%;
    left: 50%;
    font-size: 18px;
    font-family: "gordita_medium";
    transform: translate(-50%, -50%);
`;

const Name = styled.span`
    font-size: 13px;
    margin: 0 10px;
    font-family: "gordita_medium";
    text-transform: uppercase;
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
    @media all and (max-width: 360px) {
        font-size: 10px;
    }
`;
const Downarrow = styled.div`
    width: 15px;
    cursor: pointer;
`;
const Arrow = styled.img`
    width: 100%;
    display: block;
`;
const DivLogout = styled.div`
    position: relative;
    display: none;
    transition: 2s ease-in-out;
    transition-delay: 2ms;
    cursor: pointer;
    &.active {
        display: block;
        transition: 2s ease-in-out;
        transition-delay: 2ms;
    }
`;
const DivDrop = styled.div`
    position: absolute;
    right: 20px;
    top: 10%;
    background-color: #fff;
    padding: 15px 0px;
    border-radius: 3px;
    box-shadow: 5px 5px 20px #888888;
    width: 150px;
    position: fixed;
    transition: 2s ease-in-out;
    transition-delay: 2ms;
    z-index: 99999;
`;
const DivBottom = styled.div`
    display: flex;
    cursor: pointer;
    justify-content: space-evenly;
    width: 110px;
`;
const ImgDrop = styled.img`
    display: block;
    width: 100%;
`;
const Image = styled.div`
    width: 15px;
`;
const Icon = styled.span`
    display: inline-block;
    font-size: 13px;
    color: #000;
    font-family: "gordita_regular";
`;
