import React, { Suspense } from "react";
import "../../../assets/css/style.css";
import { Navigate, Route, Routes } from "react-router-dom";
//router
import EventsRouter from "./EventsRouter";
//components
import TechiesParkLandingPage from "../../screens/TechiesParkLandingPage";
import TechiesparkDashboard from "../../screens/TechiesparkDashboard";
import SectionLoader from "../../includes/loaders/SectionLoader";
import styled from "styled-components";
import PageNotFound from "../../includes/PageNotFound";
import ErrorPage from "../../includes/ErrorPage";

export default function AppRouter() {
    return (
        <Suspense
            fallback={
                <Cover>
                    <SectionLoader />
                </Cover>
            }
        >
            <Routes>
                <Route path="/" element={<Navigate to="/techies-parks/" />} />
                <Route
                    path="/techies-parks/"
                    element={<TechiesparkDashboard />}
                />

                <Route
                    path="/techies-park/:techiespark_id/"
                    element={<TechiesParkLandingPage />}
                />
                <Route
                    path="/techies-park/:techiespark_id/events/*"
                    element={<EventsRouter />}
                />
                <Route path="/*" element={<PageNotFound />} />
                <Route path="/error" element={<ErrorPage />} />
            </Routes>
        </Suspense>
    );
}
const Cover = styled.div`
    height: 100vh;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`;
