import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { techiesparkConfig } from "../../../axiosConfig";
import { Context } from "../../contexts/Store";
import PlaceHolder from "../../general/PlaceHolder";
//modal
import SingleEventModal from "../../includes/events/modal/SingleEventModal";
import SectionLoader from "../../includes/loaders/SectionLoader";

export default function EventGallery() {
    const [selectedId, setSelectedId] = useState({});
    const [selectedImage, setSelectedImage] = useState({});
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isModal, setModal] = useState(false);
    const [gallery, setGallery] = useState([]);
    const { techiespark_id, event_id } = useParams();
    const [isEventsLoading, setEventsLoading] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const {
        state: {
            user_data: { access_token },
            events_data: { event_no },
        },
        dispatch,
    } = useContext(Context);

    //image loading
    const [imageLoading, setImageLoading] = useState(true);
    function onLoad() {
        setTimeout(() => {
            setImageLoading(false);
        }, 1500);
    }

    useEffect(() => {
        setEventsLoading(true);
        techiesparkConfig
            .get(`techies-parks/event/gallery/${event_id}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            })
            .then(function (response) {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setGallery(data);
                    setEventsLoading(false);
                    setLoading(false);
                } else if (StatusCode === 6001) {
                    setEventsLoading(false);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setEventsLoading(false);
                setLoading(false);
                dispatch({
                    type: "UPDATE_ERROR",
                    error: err,
                    errorMessage: "Server error please try again",
                });
            });
    }, [event_id, event_no]);

    return (
        <MainCOntainer className="wrapper">
            <TopSection>
                <Title>
                    Moment <Span>Gallery</Span>
                </Title>
            </TopSection>
            {isLoading ? (
                <Cover>
                    <SectionLoader />
                </Cover>
            ) : (
            <>
                {gallery.length > 0 ? (
                    <>
                        <BottomSection>
                            {gallery.map((item, index) => (
                                <ImageConatiner
                                    onClick={() => {
                                        setSelectedId(item.id);
                                        setSelectedImage(item.image);
                                        setModal(true);
                                        setCurrentIndex(index);
                                    }}
                                >
                                    <PlaceHolder
                                        isLoading={imageLoading}
                                        paddingTop="61.5%"
                                    />
                                    <Img
                                        style={{
                                            display: imageLoading
                                                ? "none"
                                                : "block",
                                        }}
                                        src={item?.image}
                                        onLoad={onLoad}
                                        alt="Moment"
                                    />
                                </ImageConatiner>
                            ))}
                        </BottomSection>
                    </>
                ) : (
                    <h1>No event Found!!</h1>
                )}
            </> )}
            <SingleEventModal
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                gallery={gallery}
                isModal={isModal}
                setModal={setModal}
            />
        </MainCOntainer>
    );
}
const MainCOntainer = styled.div`
    padding: 80px 0px;

    height: 100%;
    animation: 300ms ease-in-out 0ms 1 normal none running fade;
    @keyframes fade {
        0% {
            opacity: 0;
            transform: scale(0.9);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    @media all and (max-width: 980px) {
        padding: 80px 0px;
    }
    @media all and (max-width: 768px) {
        padding: 70px 0px;
    }
    @media all and (max-width: 640px) {
        padding: 50px 0px;
    }
    &.wrapper {
        width: 92%;
        margin: 0 0px 0px auto;
        @media all and (max-width: 640px) {
            width: 95%;
        }
    }
    h1 {
        font-size: 28px;
        @media all and (max-width: 980px) {
            font-size: 22px;
        }
        @media all and (max-width: 768px) {
            font-size: 20px;
            text-align: center;
        }
        @media all and (max-width: 640px) {
            font-size: 18px;
        }
        @media all and (max-width: 360px) {
            font-size: 14px;
        }
    }
`;
const TopSection = styled.div`
    margin-bottom: 30px;
`;
const Title = styled.h2`
    color: #000;
    font-size: 28px;
    font-family: gordita_medium;
    @media all and (max-width: 768px) {
        font-size: 26px;
        text-align: center;
    }
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
`;
const Span = styled.span`
    color: #62af84;
    font-size: 28px;
    font-family: gordita_medium;
    position: relative;
    @media all and (max-width: 768px) {
        font-size: 26px;
    }
    @media all and (max-width: 480px) {
        font-size: 18px;
    }

    &::before {
        content: "";
        background-image: url("https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/green-layer.png");
        width: 65%;
        height: 100%;
        position: absolute;
        background-size: contain;
        top: 30px;
        left: 0;
        background-repeat: no-repeat;
        right: 0;
        margin: 0 auto;
    }
`;

const BottomSection = styled.div`
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;
    }
`;
const ImageConatiner = styled.div`
    display: flex;
    max-width: 325px;
    min-width: 325px;
    margin-right: 15px;
    border-radius: 4px;
    cursor: pointer;
    @media all and (max-width: 768px) {
        margin-bottom: 20px;
    }
    @media all and (max-width: 480px) {
        min-width: 261px;
    }
`;
const Img = styled.img`
    display: block;
    width: 100%;
    border-radius: 4px;
    &:hover {
        /* border: 1px solid #0fa76f; */
    }
`;
const Cover = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
